/* eslint-disable max-classes-per-file */
import { Type, plainToClass } from "class-transformer";
import { ECONOMY_SERVICE_AREA } from "views/createNew/offer/wizard/consts/offer-contst";
import { Frequency } from "views/createNew/offer/wizard/components/service-details-header";

export const INCOME_TAX_COMPANY_DISPLAY = "company";
export const INCOME_TAX_INDIVIDUAL_DISPLAY = "individual";

// Offer Static data is data from the Service Matrix
export interface ServiceMatrix {
  serviceAreas: ServiceArea[];
}

export class PricePrerequisite {
  id: string;

  current_invoicing: number;

  coverage_rate: number;

  member_of_lrf: boolean;

  lowest_possible_price: number;

  proposed_price: number;

  price_is_acceptable: boolean;

  analog_customer: string;

  exclude_from_analog_addition: null | string;

  min_price_adjustment: number;

  min_discounted_price: number;

  @Type(() => Date)
  changed_date: Date;

  @Type(() => Date)
  created_date: Date;
}

// Wrapper on the serviceLines,
export class ServiceArea {
  id?: string | null;

  name: string;

  @Type(() => ServiceLine)
  service_lines: ServiceLine[];
}

export class ServiceLine {
  id?: string | null;

  name: string;

  @Type(() => ServiceGroup)
  service_groups: ServiceGroup[];

  sorting_number: number;

  authorized_by: string | null;
}

export class ServiceGroup {
  id?: string | null;

  name: string;

  @Type(() => ServiceCategory)
  service_categories: ServiceCategory[];

  sorting_number: number;

  skip_price_calculation: boolean;

  static updateServiceGroupInServiceArea(
    serviceAreas: ServiceArea[],
    newServiceGroup: ServiceGroup
  ): ServiceArea[] {
    return serviceAreas.map((serviceArea) => {
      return {
        ...serviceArea,
        service_lines: serviceArea.service_lines.map((serviceLine) => {
          return {
            ...serviceLine,
            service_groups: serviceLine.service_groups.map((serviceGroup) => {
              if (serviceGroup.name === newServiceGroup.name) {
                return newServiceGroup;
              }
              return serviceGroup;
            }),
          };
        }),
      };
    });
  }
}

export class IndexedPrice {
  changed_date: Date;

  created_date: Date;

  id: number;

  index_price: number;

  pricing_year: string;
}

export class ServiceCategory {
  constructor() {
    this.services = [];
  }

  id?: number | null;

  name: string;

  project_manager: string;

  frequency: Frequency | "";

  start_date: string;

  end_date: string;

  project_type: string;

  price_prerequisite?: PricePrerequisite;

  @Type(() => Service)
  services: Service[];

  discount: number;

  discount_comment: string | null;

  price_adjustment: number;

  calculated_price: number;

  final_price: number;

  estimated_time: number;

  fixed_price: number;

  dynamic_price: number;

  hourly_price: number;

  estimated_cost?: number;

  estimated_unit_price?: number;

  turnover?: string | null;

  year_end_year: string | null;

  year_end_month?: string | null;

  sorting_number: number;

  approved_by_email: string;

  indexed_prices: IndexedPrice[];

  static updateServiceCategoryInServiceArea(
    serviceAreas: ServiceArea[],
    newServiceCategory: ServiceCategory
  ): ServiceArea[] {
    return serviceAreas.map((serviceArea) => {
      return {
        ...serviceArea,
        service_lines: serviceArea.service_lines.map((serviceLine) => {
          return {
            ...serviceLine,
            service_groups: serviceLine.service_groups.map((serviceGroup) => {
              return {
                ...serviceGroup,
                service_categories: serviceGroup.service_categories.map(
                  (serviceCategory) => {
                    if (serviceCategory.name === newServiceCategory.name) {
                      return newServiceCategory;
                    }
                    return serviceCategory;
                  }
                ),
              };
            }),
          };
        }),
      };
    });
  }
}

export class Service {
  id?: string | null;

  service_matrix_id: string;

  pricing_type: string;

  unit_price: number;

  units: number;

  pog_level: string;

  sorting_number: number;

  pog_header_sorting_number: number;

  pricing_adjustment: number;

  discount: number;

  discount_comment: string | "";

  process_description: string;

  name: string;

  calculated_price: number;

  final_price: number;

  estimated_time: number;

  header: string;

  input_form_type: string;

  input_form_type_state: boolean;

  input_form_type_content: string;

  input_form_type_value: string;

  input_is_disabled: boolean;

  pog_display_level: string;

  pog_income_tax: boolean;

  is_responsibility: boolean;

  responsibility_input_is_disabled: boolean;

  responsible_default_is_firm: boolean;

  responsible_party: string;

  is_pog_service_frequency: boolean;

  pog_service_frequency: string;

  pog_tooltip_se: string;

  pog_tooltip_en: string;

  static isServiceActive(service: Service): boolean {
    return service.input_form_type !== "";
  }

  static getIncomeTaxServices(services: Service[]): Service[] {
    return services.filter((s) => s.pog_income_tax);
  }

  static getIncomeTaxServicesForCompany(services: Service[]): Service[] {
    return this.getIncomeTaxServices(services).filter(
      (s) => s.pog_display_level !== INCOME_TAX_INDIVIDUAL_DISPLAY
    );
  }

  static getIncomeTaxServicesForIndividual(services: Service[]): Service[] {
    return this.getIncomeTaxServices(services).filter(
      (s) => s.pog_display_level !== INCOME_TAX_COMPANY_DISPLAY
    );
  }

  static getActiveServices(services: Service[]): Service[] {
    return services.filter((s) => this.isServiceActive(s));
  }

  static sortServices(services: Service[]): Service[] {
    return services.sort(
      (serviceA, serviceB) => serviceA.sorting_number - serviceB.sorting_number
    );
  }
}

export function getServiceCategoryFromEconomyServiceArea(
  serviceLineName: string,
  serviceGroupName: string,
  serviceCategoryName: string,
  serviceAreas: ServiceArea[]
) {
  return serviceAreas
    ?.find((sa) => sa.name === ECONOMY_SERVICE_AREA)
    ?.service_lines.find((sl) => sl.name === serviceLineName)
    ?.service_groups?.find((sg) => sg.name === serviceGroupName)
    ?.service_categories?.find((sc) => sc.name === serviceCategoryName);
}

export function getFilteredAndSortedServices(
  serviceCategory: ServiceCategory
): Service[] {
  const services = plainToClass(Service, serviceCategory.services);
  return Service.sortServices(Service.getActiveServices(services));
}

export type ServiceGroupItem = {
  header: string;
  sortingNumber: number;
  services: Service[];
};

export type ServiceContentLookupOption = {
  value: string;
  output: string;
};

/* eslint-disable camelcase */
import { useState } from "react";
import { useSelector } from "react-redux";
import { ArrowSort20Regular, Filter20Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import { RootState } from "state";
import { useTranslation } from "hooks";
import ContextualMenu from "components/contextualMenu/ContextualMenu";
import { renderIcon } from "libs/render-icon";
import { Dropdown, OptionItem } from "components/dropdown/Dropdown";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
  LayoutSubHeader,
} from "components/layout/Layout";
import Checkbox from "components/checkbox";
import SearchInput from "components/input/search";
import { PersonPicker } from "components/people";
import {
  FilterSortBox,
  FilterSortList,
  FiltersPropsList,
} from "views/conveyorBelt/components/overview/components/FilterSortBox";
import {
  SearchByEnum,
  TabViewValueEnum,
} from "constants/enums/conveyorBelts.enum";
import "../../../index.scss";

interface TabViewOptions {
  value: string;
  text: string;
}

type Props = {
  filtersPropsList: FiltersPropsList;
  selectedTabView: string;
  setSelectedTabView: React.Dispatch<React.SetStateAction<string>>;
  setIncompleteFilterEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  searchBy: string;
  searchByAssignee: string;
  setSearchByAssignee: (searchByAssignee: string) => void;
  handleSearchBySelect: (optionValue?: string) => void;
  fetchMyCustomers: () => void;
  customerSearchTerm: string;
  searchCustomers: () => void;
  handleOnSearchInputChange: (
    nativeEvent: MouseEvent | Event,
    value: string
  ) => void;
};

export default function AssignmentManagementHeader({
  filtersPropsList,
  selectedTabView,
  setSelectedTabView,
  setIncompleteFilterEnabled,
  searchBy,
  searchByAssignee,
  setSearchByAssignee,
  handleSearchBySelect,
  fetchMyCustomers,
  customerSearchTerm,
  searchCustomers,
  handleOnSearchInputChange,
}: Props) {
  const { translate } = useTranslation();

  const { isLoading: isCustomersLoading } = useSelector(
    (state: RootState) => state.customers
  );

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);

  const [searchInputValue, setSearchInputValue] = useState(customerSearchTerm);

  const getDefaultValueForView = (tabView: string) => {
    switch (tabView) {
      case TabViewValueEnum.ACTIVE:
        return translate("ACTIVE_VIEW");
      case TabViewValueEnum.COLLAPSED:
        return translate("COLLAPSED_VIEW");
      default:
        return translate("STANDARD_VIEW");
    }
  };

  const getDefaultValueForSearchBy = (by: string) => {
    switch (by) {
      case SearchByEnum.MyCustomers:
        return translate("MY_CUSTOMERS");
      case SearchByEnum.AllCustomers:
        return translate("ALL_CUSTOMERS");

      default:
        return translate("ASSIGNEE");
    }
  };

  const tabViewOptions: TabViewOptions[] = [
    {
      value: TabViewValueEnum.ALL,
      text: translate("STANDARD_VIEW"),
    },
    {
      value: TabViewValueEnum.ACTIVE,
      text: translate("ACTIVE_VIEW"),
    },
    {
      value: TabViewValueEnum.COLLAPSED,
      text: translate("COLLAPSED_VIEW"),
    },
  ];

  const searchByOptions: OptionItem[] = [
    {
      value: SearchByEnum.MyCustomers,
      text: getDefaultValueForSearchBy(SearchByEnum.MyCustomers),
    },
    {
      value: SearchByEnum.AllCustomers,
      text: getDefaultValueForSearchBy(SearchByEnum.AllCustomers),
    },
    // TODO: Commented for now, until data issues are resolved
    // {
    //   value: SearchByEnum.Employee,
    //   text: getDefaultValueForSearchBy(SearchByEnum.Employee),
    // },
  ];

  return (
    <>
      <LayoutHeader scrollable>
        <LayoutHeaderLeft className="mr-lg">
          <div className="d-flex align-items-center justify-content-between">
            <div className="pr-md">
              <h1>{translate("MY_ASSIGNMENTS")}</h1>
            </div>
            <div className="vertical-divider pr-sm" />
            <label className="pr-sm">{translate("VIEW")}:</label>
            <Dropdown
              className="dropdown-view width-max"
              dropdownClassName="dropdown-view"
              appearance="underline"
              selectedOptions={[selectedTabView]}
              defaultValue={getDefaultValueForView(selectedTabView)}
              onOptionSelect={(_, { optionValue }) => {
                if (optionValue) {
                  setIncompleteFilterEnabled(
                    optionValue === TabViewValueEnum.ACTIVE ||
                      optionValue === TabViewValueEnum.COLLAPSED
                  );
                  setSelectedTabView(optionValue);
                }
              }}
              options={tabViewOptions}
            />
          </div>
        </LayoutHeaderLeft>
        <LayoutHeaderRight className="d-flex flex-none">
          <div className="d-flex flex-wrap fg-sm align-items-center">
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-row justify-content-center align-items-center mr-sm">
                <FilterSortList {...filtersPropsList} />
              </div>
              <Button
                appearance="transparent"
                className={showAdditionalFilters ? "active-state-bg" : ""}
                icon={<Filter20Regular />}
                onClick={() => setShowAdditionalFilters(!showAdditionalFilters)}
              >
                <span className="fw-600 px-xs">{translate("FILTER")}</span>
              </Button>
              <ContextualMenu
                buttonText={translate("SORT")}
                buttonStyle="sort-button ghost-button min-width-fit-content"
                buttonIcon={() =>
                  renderIcon(<ArrowSort20Regular className="pb-xxs mr-sm" />)
                }
                menuItems={[
                  {
                    disabled: filtersPropsList.disableSort,
                    content: filtersPropsList.disableSort ? undefined : (
                      <Checkbox
                        className="mr-sm align-items-center"
                        checked={filtersPropsList.handedDocs}
                        onChange={() =>
                          filtersPropsList.setHandedDocs(
                            !filtersPropsList.handedDocs
                          )
                        }
                        noMargin={false}
                      />
                    ),
                    persistOnClick: true,
                    text: translate("SORT_BY_DATE"),
                    tooltip: filtersPropsList.disableSort
                      ? { content: "SORT_UNAVAILABLE" }
                      : undefined,
                  },
                ]}
              />
            </div>
            <div className="reload-vertical-divider" />
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Dropdown
                className="dropdown-view search-by-dropdown"
                appearance="underline"
                label={translate("SEARCH_BY")}
                disabled={isCustomersLoading}
                options={searchByOptions}
                selectedOptions={[searchBy]}
                defaultValue={getDefaultValueForView(selectedTabView)}
                onOptionSelect={(_, { optionValue }) =>
                  handleSearchBySelect(optionValue)
                }
              />

              <div className="vw-15 ml-sm">
                {searchBy === SearchByEnum.Employee ? (
                  <PersonPicker
                    className={searchByAssignee ? "" : `empty-person-picker-bg`}
                    selectionMode="single"
                    selectedUsers={
                      searchByAssignee ? [{ id: searchByAssignee }] : []
                    }
                    onPersonChange={(_, __, person) =>
                      setSearchByAssignee(person?.userPrincipalName || "")
                    }
                    removeSelectedUser={() => {
                      setSearchByAssignee("");
                      fetchMyCustomers();
                    }}
                    disabled={isCustomersLoading}
                  />
                ) : (
                  <SearchInput
                    className="w-100"
                    placeholder={translate("SEARCH_PLACEHOLDER")}
                    value={searchInputValue}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        searchBy === SearchByEnum.AllCustomers
                      ) {
                        searchCustomers();
                      }
                    }}
                    onChange={({ nativeEvent }, { value }) => {
                      setSearchInputValue(value);
                      handleOnSearchInputChange(nativeEvent, value);
                    }}
                    data-testid="search-input"
                    disabled={isCustomersLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </LayoutHeaderRight>
      </LayoutHeader>
      {showAdditionalFilters && (
        <LayoutSubHeader>
          <FilterSortBox {...filtersPropsList} />
        </LayoutSubHeader>
      )}
    </>
  );
}

import { useState } from "react";
import { useSelector } from "react-redux";
import { Checkmark20Regular } from "@fluentui/react-icons";

import { RootState } from "state";
import { addTemporaryAccessToCustomer } from "state/customers/actions";
import { useAppDispatch } from "state/use-app-redux";
import { useTranslation } from "hooks/use-translate";
import { Customer } from "models/customer";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { isCustomerSupportUser } from "libs/customer-settings-helpers";
import { useAssignmentManagementContext } from "views/conveyorBelt/context/AssignmentManagementContext";

type TemporaryAccessModalProps = {
  isOpen: boolean;
  customer: Customer;
  onDismiss: () => void;
};

export function TemporaryAccessModal({
  isOpen,
  customer,
  onDismiss,
}: TemporaryAccessModalProps) {
  const { translate } = useTranslation();
  const dispatch = useAppDispatch();

  const { currentUser } = useSelector((state: RootState) => state.users);
  const { setSearchToMyCustomers } = useAssignmentManagementContext();

  const [isLoading, setIsLoading] = useState(false);

  const handleOnSave = async () => {
    setIsLoading(true);
    const isSupportUser = isCustomerSupportUser(currentUser);
    try {
      const response = await dispatch(
        addTemporaryAccessToCustomer({
          customerId: customer.customer_number,
          customerName: customer.name,
          isSupportUser,
        })
      );
      if (response.meta.requestStatus === "fulfilled") {
        setSearchToMyCustomers();
      }
    } finally {
      setIsLoading(false);
      onDismiss();
    }
  };

  return (
    <Modal
      onDismiss={onDismiss}
      isOpen={isOpen}
      size="small-to-mid"
      header={
        <ModalHeader headerTitleText="CUSTOMER_DETAILS_MENU.GET_TEMPORARY_ACCESS_MODAL.HEADER" />
      }
      footer={
        <ModalFooter
          onSave={handleOnSave}
          labelSubmit="CONFIRM"
          onCancel={onDismiss}
          isLoading={isLoading}
          sendButtonIcon={<Checkmark20Regular className="mr-sm" />}
        />
      }
    >
      <div className="mb-md">
        <span className="p-sm">
          {translate(
            "CUSTOMER_DETAILS_MENU.GET_TEMPORARY_ACCESS_MODAL.BODY_TEXT",
            [customer.name]
          )}
        </span>
      </div>
    </Modal>
  );
}

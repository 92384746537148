import { useState } from "react";
import { Row } from "react-bootstrap";
import { Spinner } from "@fluentui/react-components";

import { authMethod } from "auth";
import { CustomersAPI } from "api/customers";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import { useTranslation } from "hooks/use-translate";
import { Content, ContentBody } from "components/content/Content";
import SearchInput from "components/input/search";
import {
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutWrapper,
} from "components/layout/Layout";
import { SpinnerSize } from "components/spinner";
import { ResponseTable } from "./ResponseTable";

export type searchSSNType = {
  system: string;
  ssn_exists: boolean;
  comment: string;
};

export function Legal() {
  const { translate } = useTranslation();
  const [isLoadingSSN, setIsLoadingSSN] = useState(false);
  const [customerSearchBySSN, setCustomerSearchBySSN] = useState("");
  const [searchSSNError, setSearchSSNError] = useState(false);
  const [searchResult, setSearchResult] = useState<searchSSNType[]>();

  const handleSearchInputChange = (value: string) => {
    setCustomerSearchBySSN(value);
    setSearchSSNError(false);
    setSearchResult([]);
  };

  const isExistingSSN = async () => {
    const ssnRegex = /^(?:[0-9]{6})-?[0-9]{4}$/;
    const isMatch = ssnRegex.test(customerSearchBySSN);

    if (!isMatch) {
      setSearchSSNError(true);
    } else {
      const normalizedNumber = normalizeOrgNrAndSSN(customerSearchBySSN);
      try {
        setIsLoadingSSN(true);
        const token = await authMethod.getStoredAccessToken();
        const searchSSNObj = await CustomersAPI.searchCustomerBySSN(
          token,
          normalizedNumber
        );

        setSearchResult(searchSSNObj);
        setSearchSSNError(false);
        return true;
      } finally {
        setIsLoadingSSN(false);
      }
    }
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("LEGAL_HEADER")}</h1>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutWrapper>
        <Content>
          <div className="contentHeader d-block">
            <h3 className="contentHeader--title">
              {translate("CONFLICT_OF_INTEREST_HEADER_TITLE")}
            </h3>
          </div>
          <ContentBody>
            <Row className="d-flex m-auto w-35 mt-xxl">
              <SearchInput
                label={translate("SSN_SEARCH_FIELD_LABEL")}
                placeholder={translate("SSN_SEARCH_CUSTOMER_PLACEHOLDER")}
                value={customerSearchBySSN}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    isExistingSSN();
                  }
                }}
                onChange={(_, { value }) => handleSearchInputChange(value)}
                data-testid="search-input"
                disabled={isLoadingSSN}
                hintMessage={
                  <span className="text-gray fs-italic fw-400 fpx-12">
                    {translate("ACCEPTED_SSN_FORMATS")}
                  </span>
                }
                errorMessage={
                  searchSSNError && (
                    <span className="error-message mt-sm text-color-red">
                      {translate("INVALID_SSN")}
                    </span>
                  )
                }
              />
            </Row>
            <Row className="m-auto search-customer w-35 mt-xxl px-sm">
              {isLoadingSSN ? (
                <Spinner size={SpinnerSize.Medium} />
              ) : (
                searchResult &&
                searchResult.length > 0 && (
                  <ResponseTable content={searchResult} />
                )
              )}
            </Row>
          </ContentBody>
        </Content>
      </LayoutWrapper>
    </>
  );
}

import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";

import { OverlaySpinner } from "components/spinner";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { getServiceCategoryWithDefaultServices } from "helpers/DealToPogHelper";
import { useTranslation } from "hooks";
import { Service, ServiceGroup, ServiceLine } from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { RootState } from "state";
import { appendError, appendToastMessage } from "state/notifications";
import {
  addOfferSelectedServiceLine,
  addServiceLineGroup,
  removeOfferSelectedServiceLine,
  removeServiceLineGroup,
  updateCurrentOffer,
} from "state/offer/offersSlice";
import {
  sendOfferForCalculation,
  updateCustomerIncomeTaxData,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { ServicePicker } from "views/createNew/offer/components/servicePicker/ServicePicker";
import { availableTogglesMeta } from "views/createNew/offer/components/servicePicker/ServicePickerHelper";
import { WizardSection } from "views/createNew/offer/components/wizardSection/index";
import { ECONOMY_SERVICE_AREA } from "../consts/offer-contst";
import { ServicesContext } from "./ServicesContextProvider";

export type AvailableServiceOption = {
  name: string;
  description: string;
  subServices: {
    value: string;
    label: string;
  }[];
};
export function Services() {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();

  const { serviceGroups } = useContext(ServicesContext);

  const { offerTemplate: availableServices } = useSelector(
    (state: RootState) => state.offers
  );
  const { data: currentOffer, status: offerLoadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const user = useSelector((state: RootState) => state.users.currentUser);

  if (!currentOffer || !currentOffer.service_areas) {
    return <>Missing customer offer</>;
  }
  const serviceAreas = currentOffer.service_areas.find(
    (area) => area.name === ECONOMY_SERVICE_AREA
  );
  if (!serviceAreas) {
    return <>Missing TOEK service area</>;
  }
  const selectedServices = serviceAreas.service_lines || [];
  const availableServiceLines = availableServices.data?.service_areas.flatMap(
    (sa) => sa.service_lines
  );
  if (!availableServiceLines) {
    return <OverlaySpinner />;
  }
  const hasServiceCategories =
    selectedServices
      .flatMap((sl) => sl.service_groups)
      .flatMap((sg) => sg.service_categories).length > 0;

  const customer = currentOffer?.customer;

  if (!currentOffer) {
    throw new Error("Missing customer offer");
  }

  if (!customer) {
    throw new Error("Missing customer");
  }
  const hasSelectedServiceLine = (service: ServiceLine) =>
    selectedServices.some((ss) => ss.name === service.name);

  const hasSelectedServiceLineGroup = (
    service: ServiceLine,
    group: ServiceGroup
  ) => {
    const selectedServiceLines = currentOffer.service_areas.flatMap(
      (sa) => sa.service_lines
    );
    const selectedServiceArea = selectedServiceLines.find(
      (sa) => sa.name === service.name
    );
    return !!selectedServiceArea?.service_groups.some(
      (s) => s.name === group.name
    );
  };

  const createIncomeTaxObjectServices = (services: Service[]) => {
    const incomeTaxServices = services.filter(
      (service) => service.pog_income_tax
    );
    if (incomeTaxServices.length > 0) {
      const companyTaxObject = TaxObjectDetails.getTaxObjectFromCustomerData(
        customer,
        user
      );
      const newTaxObjectsServices: TaxObjectService[] = incomeTaxServices.map(
        (service) => ({
          customer_tax_object: companyTaxObject,
          service_matrix_id: service.service_matrix_id,
        })
      );
      dispatch(
        updateCurrentOffer({
          tax_objects: newTaxObjectsServices ?? [],
        })
      );
      dispatch(
        updateCustomerIncomeTaxData({
          taxDetailsFormData: companyTaxObject,
          newTaxObjectsServices,
        })
      );
    }
  };

  function addServiceGroup(serviceGroup: ServiceGroup) {
    const groupCatagories = serviceGroup.service_categories
      .filter((category) => category.name !== undefined)
      .map((sc) => {
        const serviceCategory = getServiceCategoryWithDefaultServices(sc);
        return serviceCategory;
      });

    if (groupCatagories.length > 0) {
      const allServices = groupCatagories.flatMap((sc) => sc.services);
      createIncomeTaxObjectServices(allServices);

      const serviceGroupFromContext = serviceGroups.find(
        (group) => group.name === serviceGroup.name
      );

      if (serviceGroupFromContext) {
        dispatch(addServiceLineGroup(serviceGroupFromContext));
      } else {
        const newGroup: ServiceGroup = {
          name: serviceGroup.name,
          service_categories: groupCatagories,
          sorting_number: serviceGroup.sorting_number,
          skip_price_calculation: serviceGroup.skip_price_calculation,
        };
        dispatch(addServiceLineGroup(newGroup));
      }
    }
  }

  const handleOnBeforeNext = async () => {
    if (!currentOffer.id) {
      const updatedOffer = await dispatch(sendOfferForCalculation()).unwrap();
      dispatch(appendToastMessage("DRAFT_OFFER_CREATED", "success"));
      if (updatedOffer.hubspot_deal_id) {
        if (!currentOffer.deal_source) {
          dispatch(
            appendError("FAILED_TO_UPDATE_DEAL_SOURCE", {
              message: `deal_source for offer with ID ${currentOffer.id} and HubSpot ID ${updatedOffer.hubspot_deal_id} cannot be set`,
            } as Error)
          );
        }
      }
    }
  };

  return (
    <WizardSection
      loadingStatus={offerLoadingStatus}
      isNextHidden={!hasServiceCategories}
      onBeforeNext={async () => {
        await handleOnBeforeNext();
        return true;
      }}
      subtitles={[translate("SERVICES_INFO")]}
    >
      <Row className="minHeight-100">
        <Col xl={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
        <Col xl={8} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            {availableServiceLines.length === 0 && (
              <>Missing service areas / service lines</>
            )}
            {availableServiceLines.map((serviceLine) => (
              <ServicePicker
                key={serviceLine.name}
                name={serviceLine.name}
                enabled={selectedServices.some(
                  (s) => s.name === serviceLine.name
                )}
                description={translate(
                  serviceLine.name === ACCOUNTING_SERVICE_LINE
                    ? "CLICK_FOR_ACCOUNTING"
                    : "CLICK_FOR_SERVICE"
                )}
                className="mx-sm"
                onToggle={() => {
                  if (!hasSelectedServiceLine(serviceLine)) {
                    dispatch(addOfferSelectedServiceLine(serviceLine));
                  } else {
                    dispatch(removeOfferSelectedServiceLine(serviceLine));
                  }
                }}
                serviceToggles={serviceLine.service_groups.map((group) => {
                  const serviceSelected = hasSelectedServiceLine(serviceLine);
                  const groupSelected = hasSelectedServiceLineGroup(
                    serviceLine,
                    group
                  );

                  return {
                    value: group.name,
                    label: group.name,
                    enabled: groupSelected,
                    isPackage: !!availableTogglesMeta[group.name]?.isPackage,
                    onToggle: () => {
                      if (!serviceSelected) {
                        throw new Error("Has not added service line");
                      }
                      if (groupSelected) {
                        dispatch(removeServiceLineGroup(group));
                      } else {
                        addServiceGroup(group);
                      }
                    },
                  };
                })}
              />
            ))}
          </div>
        </Col>
        <Col xl={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSection>
  );
}

import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import "../Services/ServiceForm.scss";
import { useTranslation } from "hooks/use-translate";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { sendOfferForCalculation } from "state/offer/offersThunks";
import {
  getServiceCategoryFromEconomyServiceArea,
  Service,
  ServiceCategory,
} from "models/offer/ServiceLine";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { isEqualSSN } from "libs/is-equal-ssn";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import ServiceDetailsHeader from "views/createNew/offer/wizard/components/service-details-header";
import {
  getNextButtonText,
  OfferRouteHelper,
} from "views/createNew/offer/routes/offerRoutes";
import ServicesFormData from "views/createNew/offer/wizard/Services/ServicesFormData";
import { TaxObjectForm } from "views/createNew/offer/wizard/IncomeTax/TaxObjectForm";
import { useGetBreadcrumbs } from "views/createNew/offer/components/wizardSection/useGetBreadcrumbs";
import useSaveTaxObjectsData from "hooks/createNew/offer/use-save-tax-objects-data";
import {
  SERVICE_CATEGORY_INCOME_TAX,
  SERVICE_GROUP_INCOME_TAX,
} from "../consts/offer-contst";
import { ServicesContext } from "../Services/ServicesContextProvider";
import ServiceFormWithTaxObjects from "../../components/serviceFormWithTaxObjects/serviceFormWithTaxObjects";

export type IncomeTaxForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  services: Service[];
};

function IncomeTax() {
  const { translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useDispatch();
  const location = useLocation();
  const { updateServiceGroups, updateTaxObjects, taxObjects } =
    useContext(ServicesContext);

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  const user = useSelector((state: RootState) => state.users.currentUser);

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  const [showTaxObjects, setShowTaxObjects] = useState(false);
  const [showServiceDetails, setShowServiceDetails] = useState(true);
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }
  const { customer } = currentOffer;
  if (!customer) {
    throw new Error("Missing customer in offer");
  }
  const { service_areas: currentServiceAreas } = currentOffer;

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);

  useEffect(() => {
    const updatedTaxObjects = taxObjects.incomeTax;
    if (updatedTaxObjects) {
      dispatch(
        updateCurrentOffer({
          tax_objects: updatedTaxObjects,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, taxObjects]);

  const incomeTaxTemplate =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      SERVICE_GROUP_INCOME_TAX,
      SERVICE_CATEGORY_INCOME_TAX,
      serviceAreas
    ) ?? ({} as ServiceCategory);

  const incomeTaxInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      SERVICE_GROUP_INCOME_TAX,
      SERVICE_CATEGORY_INCOME_TAX,
      currentServiceAreas
    ) ?? ({} as ServiceCategory);

  const {
    start_date: startDate,
    end_date: endDate,
    project_manager: projectManager,
    year_end_year: yearEndYear,
    year_end_month: yearEndMonth,
    frequency,
    services: activeServices,
    approved_by_email: approvedByEmail,
  } = incomeTaxInCurrentOffer;

  const customerTaxObjects = customer.tax_objects ?? [];
  const customerTaxObject =
    customerTaxObjects.find((ctObj) =>
      isEqualSSN(ctObj.social_security_number, customer.org_number)
    ) ?? TaxObjectDetails.getTaxObjectFromCustomerData(customer, user);

  const [incomeTaxData, setIncomeTaxData] = useState<IncomeTaxForm>({
    startDate: startDate !== "" ? startDate : getMonthsOfOneYear()[0],
    endDate,
    projectManager: projectManager !== "" ? projectManager : user.email,
    yearEndYear,
    yearEndMonth: yearEndMonth || "12",
    frequency: frequency ?? incomeTaxTemplate.frequency,
    services: activeServices,
  });

  const { saveFormData } = useSaveTaxObjectsData({
    serviceCategoryInCurrentOffer: incomeTaxInCurrentOffer,
    formData: incomeTaxData,
    serviceCategoryServices: incomeTaxTemplate.services,
    setIsDirty,
  });

  async function saveIncomeTaxHeaderDataToServiceLine(
    incomeTaxDataForm = incomeTaxData
  ) {
    const newIncomeTax: ServiceCategory = {
      ...incomeTaxInCurrentOffer,
      project_manager: incomeTaxDataForm.projectManager,
      start_date: incomeTaxDataForm.startDate,
      frequency: incomeTaxDataForm.frequency,
      year_end_year: incomeTaxDataForm.yearEndYear,
      year_end_month: incomeTaxDataForm.yearEndMonth,
    };

    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newIncomeTax
    );
    await dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
    setIsDirty(true);
  }

  function updateServiceHeaderData(formHeaderData: Partial<IncomeTaxForm>) {
    const newIncomeTaxFormData = { ...incomeTaxData, ...formHeaderData };
    setIncomeTaxData(newIncomeTaxFormData);
    saveIncomeTaxHeaderDataToServiceLine(newIncomeTaxFormData);
  }

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(isDirty);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const updateServiceContext = () => {
    const serviceGroup =
      currentOffer.service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === SERVICE_GROUP_INCOME_TAX
      );
    if (serviceGroup) {
      updateServiceGroups(serviceGroup);
    }
    updateTaxObjects({ incomeTax: currentOffer.tax_objects });
  };

  const handleSave = async () => {
    await saveIncomeTaxHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());

    updateServiceContext();

    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceHeaderData({
          ...incomeTaxData,
          startDate: value,
        });
      }}
      startDate={incomeTaxData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceHeaderData({
          ...incomeTaxData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={incomeTaxData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceHeaderData({
          ...incomeTaxData,
          frequency: value,
        });
      }}
      frequencySelectionDisabled={!!incomeTaxTemplate.frequency}
      frequency={incomeTaxData.frequency}
      updateServiceCategoryData={(value) => {
        updateServiceHeaderData({
          ...incomeTaxData,
          yearEndMonth: value,
        });
      }}
      yearEndData={incomeTaxData}
      yearEndInCurrentOffer={incomeTaxInCurrentOffer}
    />
  );

  const renderServiceDetailsForm = () => (
    <div className="pl-md">
      <Row className="pt-md">
        <Col>
          <label className="fw-bold">{translate("TAX_FORM_TITLE")}</label>
        </Col>
      </Row>
      <TaxObjectForm
        isForCompany
        onSave={(taxObject, taxObjectServices) =>
          saveFormData(taxObject, taxObjectServices)
        }
        dealTaxServices={currentOffer.tax_objects}
        taxObject={customerTaxObject}
        showInModal={false}
        isFormDisabled={!!approvedByEmail}
        incomeTaxServicesTemplate={Service.getIncomeTaxServicesForCompany(
          incomeTaxTemplate.services
        )}
      />
    </div>
  );

  const onBeforeNext = async () => {
    await saveIncomeTaxHeaderDataToServiceLine();
    await dispatch(sendOfferForCalculation());
    updateServiceContext();
    return true;
  };

  const renderServiceForm = () => (
    <WizardSection
      loadingStatus={loadingStatus}
      onBeforeNext={onBeforeNext}
      isNextHidden={activeServices.length === 0}
      onBeforePrevious={handleOnBeforePrevious}
      className="position-relative min-height-500"
      hideNavigation={showTaxObjects}
      hasDivider
      titleBreadCrumb={
        showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
      }
      content={serviceFormHeader}
      nextButtonText={translate("SERVICE_PAGE.CALCULATE_PRICE_BUTTON")}
    >
      <ServiceFormWithTaxObjects
        modalOpen={modalOpen}
        setOpenModal={setOpenModal}
        showServiceDetails={showServiceDetails}
        setShowServiceDetails={setShowServiceDetails}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
        isValid={activeServices.length > 0}
        showTaxObjects={showTaxObjects}
        setShowTaxObjects={setShowTaxObjects}
        renderServiceDetailsForm={renderServiceDetailsForm}
        incomeTaxServices={incomeTaxTemplate.services}
        saveFormData={saveFormData}
      />
    </WizardSection>
  );

  return edit !== undefined ? (
    <Modal
      className="service-modal"
      isOpen
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(isDirty);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="fit-content-min"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="header-blue-stripe d-flex align-items-center">
              <Breadcrumb
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    "",
                    true,
                    undefined,
                    showTaxObjects ? translate("TAX_OBJECT_LABEL") : undefined
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit={ts(
            getNextButtonText(location.pathname, currentOffer, undefined)
          )}
          onSave={() => {
            onBeforeNext();
            navigate(OfferRouteHelper.getSummary());
          }}
          isDisabled={activeServices.length === 0}
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        <ServiceFormWithTaxObjects
          modalOpen={modalOpen}
          setOpenModal={setOpenModal}
          showServiceDetails={showServiceDetails}
          setShowServiceDetails={setShowServiceDetails}
          handleSave={handleSave}
          handleDiscard={handleDiscard}
          isValid={activeServices.length > 0}
          showTaxObjects={showTaxObjects}
          setShowTaxObjects={setShowTaxObjects}
          renderServiceDetailsForm={renderServiceDetailsForm}
          incomeTaxServices={incomeTaxTemplate.services}
          saveFormData={saveFormData}
        />
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}

export default IncomeTax;

import { isEqualSSN } from "libs/is-equal-ssn";
import { Service, ServiceCategory } from "models/offer/ServiceLine";
import { TaxObjectDetails } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";
import { useSelector } from "react-redux";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { updateCustomerIncomeTaxData } from "state/offer/offersThunks";
import { useAppDispatch } from "state/use-app-redux";
import { CurrentAccountingForm } from "views/createNew/offer/wizard/CurrentAccountingSmallRecurring";
import { IncomeTaxForm } from "views/createNew/offer/wizard/IncomeTax";
import { SmallBusinessForm } from "views/createNew/offer/wizard/SmallBusiness";

type Props = {
  serviceCategoryInCurrentOffer: ServiceCategory;
  formData: CurrentAccountingForm | IncomeTaxForm | SmallBusinessForm;
  serviceCategoryServices: Service[];
  setIsDirty: (isDirty: boolean) => void;
  setCurrentAccountingServices?: React.Dispatch<
    React.SetStateAction<Service[]>
  >;
};

export default function useSaveTaxObjectsData({
  serviceCategoryInCurrentOffer,
  formData,
  serviceCategoryServices,
  setIsDirty,
  setCurrentAccountingServices,
}: Props) {
  const dispatch = useAppDispatch();

  const { data: currentOffer } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  const dealTaxObjects = currentOffer?.tax_objects ?? [];
  const { service_areas: currentServiceAreas } = currentOffer;

  /**
   * getIncomeTaxServices with updated units
   * @param newTaxObjects an array of the selected taxObjectServices
   * @param allServices list of all services from template
   * @returns Service[]
   */
  const getServices = (
    newTaxObjects: TaxObjectService[],
    allServices: Service[]
  ) =>
    newTaxObjects.reduce((newServices, taxService, _, selectedTaxServices) => {
      const isServiceAlreadyAdded = newServices.some(
        (newService) =>
          newService.service_matrix_id === taxService.service_matrix_id
      );

      if (!isServiceAlreadyAdded) {
        const service = allServices.find(
          (s) => s.service_matrix_id === taxService.service_matrix_id
        );

        if (!service) {
          throw new Error("Selected service missing in template service");
        }

        const nrOfServiceUnits = selectedTaxServices.filter(
          (stObj) => stObj.service_matrix_id === taxService.service_matrix_id
        ).length;

        const newService: Service = { ...service, units: nrOfServiceUnits };

        return [...newServices, newService];
      }
      return newServices;
    }, [] as Service[]);

  function saveDataToServiceLine(services: Service[]) {
    // Save the data to the service line in deal object
    const newServiceCategory: ServiceCategory = {
      ...serviceCategoryInCurrentOffer,
      services,
      project_manager: formData.projectManager,
      start_date: formData.startDate,
      year_end_year: formData.yearEndYear,
      year_end_month: formData.yearEndMonth,
      frequency: formData.frequency,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentServiceAreas,
      newServiceCategory
    );

    dispatch(updateCurrentOffer({ service_areas: newServiceAreas }));
    setIsDirty(true);
    return true;
  }

  async function saveFormData(
    taxObject: TaxObjectDetails,
    taxServices: TaxObjectService[],
    smallBusinessServices?: Service[]
  ) {
    // Remove existing taxServices for this ssn/org
    const filteredTaxObjects = dealTaxObjects.filter(
      (dealTaxObject) =>
        !isEqualSSN(
          dealTaxObject.customer_tax_object.social_security_number,
          taxObject.social_security_number
        )
    );

    // Filter out tax objects without selected service before sending the deal
    const filteredTaxServices = taxServices.filter(
      (taxObjectService) => taxObjectService.service_matrix_id !== ""
    );

    // Merge the new services for ssn with the existing ones
    const currentOfferTaxObjectsServices = [
      ...filteredTaxObjects,
      ...filteredTaxServices,
    ];

    // On UI still show the tax objects without selected services
    const newTaxObjectsServices = [...filteredTaxObjects, ...taxServices];

    // Save the new tax_objects_services in deal
    await dispatch(
      updateCurrentOffer({
        tax_objects: currentOfferTaxObjectsServices ?? [],
      })
    );

    // Update the customer tax object based on the newTaxObjectServices
    dispatch(
      updateCustomerIncomeTaxData({
        taxDetailsFormData: taxObject,
        newTaxObjectsServices,
      })
    );

    const newServices = getServices(
      newTaxObjectsServices,
      serviceCategoryServices
    );

    if (smallBusinessServices) {
      const newSmallBusinessServices = [
        ...smallBusinessServices,
        ...newServices,
      ];
      saveDataToServiceLine(newSmallBusinessServices);
    } else if (setCurrentAccountingServices) {
      setCurrentAccountingServices((prev) => {
        const nonIncomeTaxServices = prev.filter(
          (service) => !service.pog_income_tax
        );
        const allServices = [...nonIncomeTaxServices, ...newServices];
        const servicesToUpdate = allServices.reduce(
          (unique: Service[], service) => {
            if (
              !unique.some(
                (uniqueService) =>
                  uniqueService.service_matrix_id === service.service_matrix_id
              )
            ) {
              unique.push(service);
            }
            return unique;
          },
          []
        );
        saveDataToServiceLine(servicesToUpdate);
        return servicesToUpdate;
      });
    } else {
      saveDataToServiceLine(newServices);
    }

    setIsDirty(true);
  }

  return {
    getServices,
    saveDataToServiceLine,
    saveFormData,
  };
}

export const ECONOMY_SERVICE_AREA = "TOEK";
export const SERVICE_GROUP_SMALL_BUSINESS = "Small business";
export const SERVICE_GROUP_SMALL_BUSINESS_EF = "Small business (EF)";
export const SERVICE_GROUP_CURRENT_ACCOUNTING = "Current accounting";
export const SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_FIXED =
  "Current accounting - small fixed";
export const SERVICE_GROUP_ANNUAL_ACCOUNTS = "Annual accounts";
export const SERVICE_GROUP_INCOME_TAX = "Income tax";
export const SERVICE_CATEGORY_INCOME_TAX = "IncomeTax";
export const SERVICE_CATEGORY_SMALL_BUSINESS = "Small business";
export const SERVICE_CATEGORY_ANNUAL_ACCOUNTS = "YearEnd";
export const SERVICE_CATEGORY_CURRENT_ACCOUNTING = "Current accounting";
export const SERVICE_CATEGORY_CURRENT_ACCOUNTING_SMALL_FIXED =
  "Current accounting - small fixed";
export const SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY =
  "Small recurring monthly";
export const SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_MONTHLY_EF =
  "Small recurring monthly (EF)";

export const SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY =
  "Small recurring quarterly";
export const SERVICE_GROUP_CURRENT_ACCOUNTING_SMALL_QUARTERLY_EF =
  "Small recurring quarterly (EF)";

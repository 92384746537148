/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DrawerBody, Spinner, Tag } from "@fluentui/react-components";
import { Checkmark16Regular, Info16Filled } from "@fluentui/react-icons";

import { Button } from "components/button";
import Checkbox from "components/checkbox";
import { Dropdown } from "components/dropdown/Dropdown";
import { Input } from "components/input";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { Persona } from "components/people";
import { SpinnerSize } from "components/spinner";
import Tooltip from "components/tooltip/Tooltip";
import StatusIndicatorIcon from "components/icon/StatusIndicatorIcon";
import DrawerHeader from "components/drawer/drawerHeader/DrawerHeader";
import { useTranslation } from "hooks/use-translate";
import { isUserTeamOwner } from "libs/customer-settings-helpers";
import { Recurrence } from "models/activities/activity";
import { Customer, CustomerSettings } from "models/customer";
import { AppRouteHelper } from "routes";
import { RootState } from "state";
import {
  fetchCustomerLudvigServices,
  updateCustomerSettings,
} from "state/customers/actions";
import { useAppSelector } from "state/use-app-redux";
import OverlayDrawer from "components/OverlayDrawer";

const MITT_KONTOR_PROJECT_SUFFIX = "mk";

const ASSIGNMENT_PROJECT_ALLOWED_SUFFIXES = [
  "ei",
  "aa",
  "ek",
  "eay",
  "eby",
  "mk",
  "erlm",
  "erlq",
];

const DEFAULT_SUFFIXES_IF_FOUND = ["erlm", "erlq"];

const CustomerDetailsPanelModalState = {
  ENABLE_ARA_MODAL: "ENABLE_ARA_MODAL",
  DISABLE_ARA_MODAL: "DISABLE_ARA_MODAL",
  ENABLE_YEAREND_START_MODAL: "ENABLE_YEAREND_START_MODAL",
  DISABLE_YEAREND_START_MODAL: "DISABLE_YEAREND_START_MODAL",
  ENABLE_YEAREND_FINISH_MODAL: "ENABLE_YEAREND_FINISH_MODAL",
  DISABLE_YEAREND_FINISH_MODAL: "DISABLE_YEAREND_FINISH_MODAL",
  DELETE_MODAL: "DELETE_MODAL",
};

type Props = {
  selectedCustomer: string;
  showCustomerDetailsPanel: boolean;
  setShowCustomerDetailsPanel: (showCustomerDetailsPanel: boolean) => void;
};

export function CustomerDetailsPanel({
  selectedCustomer,
  showCustomerDetailsPanel,
  setShowCustomerDetailsPanel,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const dispatch = useDispatch();

  const CustomerHasAcceptedTerms = (c: Customer) => c.hasAcceptedTerms();

  const [modalState, setModalState] = useState<string>("");

  const hideLudvigService = true;

  const hideInterpretationCosts = true;

  const openModal = (modal: string) => {
    setModalState(modal);
  };

  const closeModal = () => {
    setModalState("");
  };

  const { currentUser } = useSelector((state: RootState) => state.users);
  const customerState = useAppSelector((state) => state.customers.data).find(
    (data) => data.customer.customer_number === selectedCustomer
  );
  const customerLudvigServicesState = useSelector(
    (state: RootState) => state.customers.customer_ludvig_services
  ).filter((data) => data.customer_number === selectedCustomer);

  const currentCustomer = customerState?.customer;

  const customerLudvigServicesYEStart =
    currentCustomer?.customerHasUnfinishedLudvigServiceFeedback(
      customerLudvigServicesState || [],
      "YEAR_END_START"
    );

  const customerLudvigServicesYEFinish =
    currentCustomer?.customerHasUnfinishedLudvigServiceFeedback(
      customerLudvigServicesState || [],
      "YEAR_END_FINISH"
    );

  const customerManagersEmailsRaw =
    currentCustomer &&
    currentCustomer.projects.map((p) => p.projectManagerEmail);

  const customerManagersEmailsUnique =
    customerManagersEmailsRaw &&
    customerManagersEmailsRaw.filter((v, i, a) => a.indexOf(v) === i);

  const handleUpdateCustomerSettings = (
    settings: Partial<CustomerSettings>
  ) => {
    if (currentCustomer) {
      dispatch(updateCustomerSettings(currentCustomer, settings));
    }
  };

  useEffect(() => {
    if (customerLudvigServicesState.length === 0) {
      dispatch(fetchCustomerLudvigServices(selectedCustomer));
    }
    const defaultAssignment = currentCustomer?.projects.find((project) =>
      DEFAULT_SUFFIXES_IF_FOUND.some((suffix) =>
        project.projectNumber.toLowerCase().endsWith(suffix)
      )
    );
    if (
      !customerState?.isLoading &&
      defaultAssignment &&
      currentCustomer &&
      currentCustomer.settings?.fortnox_interpretation_costs_allocated_for ===
        undefined
    ) {
      dispatch(
        updateCustomerSettings(currentCustomer, {
          fortnox_interpretation_costs_allocated_for:
            defaultAssignment.projectNumber,
          should_calculate_interpretation_cost: true,
        })
      );
    }
  }, [
    customerState?.isLoading,
    currentCustomer,
    selectedCustomer,
    customerLudvigServicesState,
    dispatch,
  ]);

  if (!currentCustomer) {
    return null;
  }

  function disableAssignmentYearEndCheckboxes() {
    if (!customerState || !currentCustomer) {
      return;
    }

    return (
      customerState.isLoading ||
      !currentCustomer.settings?.ludvig_service_ek_project ||
      !currentCustomer.year_end_package ||
      currentCustomer.year_end_package.toUpperCase() === Recurrence.Unknown ||
      !isUserTeamOwner(currentCustomer, currentUser)
    );
  }

  const isFortnoxCheckboxDisabled = (
    selectedAssignment: string | undefined
  ) => {
    return (
      isFortnoxDropdownDisabled(selectedAssignment) ||
      currentCustomer.settings?.fortnox_interpretation_costs_allocated_for ===
        undefined ||
      selectedAssignment?.toLowerCase().endsWith(MITT_KONTOR_PROJECT_SUFFIX)
    );
  };

  const isFortnoxDropdownDisabled = (
    selectedAssignment: string | undefined
  ) => {
    return (
      !isUserTeamOwner(currentCustomer, currentUser) ||
      customerState.isLoading ||
      DEFAULT_SUFFIXES_IF_FOUND.some((suffix) =>
        selectedAssignment?.toLowerCase().endsWith(suffix)
      )
    );
  };

  return (
    <OverlayDrawer size="medium" showPanel={showCustomerDetailsPanel}>
      <DrawerHeader
        header={currentCustomer.name}
        notTranslatable
        onDismiss={() => setShowCustomerDetailsPanel(false)}
      />
      <DrawerBody>
        <div>
          <h6 className="pt-md">
            {translate("CUSTOMER_DETAILS.CUSTOMER_INFO")}
          </h6>
          <div>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">
                  {translate("ORGANIZATION_NUMBER")}:
                </span>
                <span className="fw-bold">
                  {currentCustomer.getCompanyRegistrationNumber()}
                </span>
              </div>
            </Tag>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">{translate("CUSTOMER_NUMBER")}:</span>
                <span className="fw-bold">
                  <a
                    href={AppRouteHelper.getCustomerMaconomyUrl(
                      currentCustomer.customer_number
                    )}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {currentCustomer.customer_number}
                  </a>
                </span>
              </div>
            </Tag>
            <Tag className="m-xs">
              <div className="d-flex align-items-center">
                <span className="pr-xs">
                  {translate("CUSTOMER_DETAILS.APPROVED_TERMS")}:
                </span>
                {CustomerHasAcceptedTerms(currentCustomer) ? (
                  <div>
                    <span className="fw-bold pr-xs">
                      {translate("CUSTOMER_DETAILS.GENERAL_TERMS.ACCEPTED")}
                    </span>
                    <Checkmark16Regular />
                  </div>
                ) : (
                  <Tooltip content="GENERAL_TERMS_NOT_ACCEPTED">
                    <div>
                      <span className="fw-bold pr-xs">
                        {translate(
                          "CUSTOMER_DETAILS.GENERAL_TERMS.NOT_ACCEPTED"
                        )}
                      </span>
                      <StatusIndicatorIcon status="offline" />
                    </div>
                  </Tooltip>
                )}
              </div>
            </Tag>
          </div>
          <div className="horizontal-divider mt-sm" />
        </div>

        {currentCustomer.apps && (
          <>
            <h6 className="pt-xl">
              {translate("CUSTOMER_DETAILS.SYSTEM_STATUSES")}
            </h6>
            <table className="cell-p-2">
              <tbody>
                <tr className="no-border">
                  <td>
                    <b>Fortnox</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.fortnox.status}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Ludvig Skicka Teams</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.teams?.status ?? "loading"}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>MittKontor</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.mittkontor.status}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Datalagret</b>
                  </td>
                  <td>
                    <StatusIndicatorIcon
                      status={currentCustomer.apps.datalagret.status}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        {!hideInterpretationCosts && (
          <>
            <h6 className="pt-xl">
              {translate("FORTNOX_INVOICING_FLOW")}{" "}
              <Tooltip content="FORTNOX_INVOICING_FLOW_DESCRIPTION">
                <Info16Filled className="mr-sm" />
              </Tooltip>
            </h6>
            <table>
              <tbody>
                <tr className="no-border">
                  <td>
                    <b>{translate("SELECT_ASSIGNMENT_FOR_FORTNOX_TIC")}</b>
                  </td>
                  <td>
                    <Dropdown
                      placeholder={translate("ASSIGNMENT")}
                      selectedOptions={[
                        currentCustomer.settings
                          ?.fortnox_interpretation_costs_allocated_for ?? "",
                      ]}
                      disabled={isFortnoxDropdownDisabled(
                        currentCustomer.settings
                          ?.fortnox_interpretation_costs_allocated_for
                      )}
                      onOptionSelect={(_, { optionValue }) => {
                        if (optionValue) {
                          handleUpdateCustomerSettings({
                            fortnox_interpretation_costs_allocated_for: optionValue,
                            should_calculate_interpretation_cost:
                              !isFortnoxCheckboxDisabled(optionValue),
                          });
                        }
                      }}
                      options={currentCustomer.projects
                        .filter((project) => {
                          return ASSIGNMENT_PROJECT_ALLOWED_SUFFIXES.some(
                            (suffix) =>
                              project.projectNumber.toLowerCase().endsWith(suffix)
                          );
                        })
                        .map((project) => ({
                          value: project.projectNumber,
                          text: project.projectNumber,
                        }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translate("SHOULD_CALCULATE_INTERPRETATION_PRICE")}</b>
                  </td>
                  <td>
                    <Checkbox
                      disabled={isFortnoxCheckboxDisabled(
                        currentCustomer.settings
                          ?.fortnox_interpretation_costs_allocated_for
                      )}
                      onChange={(_, { checked }) =>
                        checked !== "mixed" &&
                        handleUpdateCustomerSettings({
                          should_calculate_interpretation_cost: checked,
                        })
                      }
                      checked={
                        currentCustomer.settings
                          ?.should_calculate_interpretation_cost || false
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {!hideLudvigService && (
          <>
            <h6 className="pt-xl">{translate("ECONOMY_ASSIGNMENTS")}</h6>
            <table>
              <tbody>
                <tr className="no-border">
                  <td>
                    <b>{translate("SELECT_ASSIGNMENT_NUMBER")}</b>
                  </td>
                  <td>
                    <Dropdown
                      placeholder={translate("ASSIGNMENT")}
                      selectedOptions={[
                        currentCustomer.settings?.ludvig_service_ek_project ??
                          "",
                      ]}
                      disabled={!isUserTeamOwner(currentCustomer, currentUser)}
                      onOptionSelect={(_, { optionValue }) => {
                        if (optionValue) {
                          handleUpdateCustomerSettings({
                            ludvig_service_ek_project: optionValue,
                          });
                        }
                      }}
                      options={currentCustomer.projects
                        .filter((project) => {
                          return ASSIGNMENT_PROJECT_ALLOWED_SUFFIXES.some(
                            (suffix) =>
                              project.projectNumber
                                .toLowerCase()
                                .endsWith(suffix)
                          );
                        })
                        .map((project) => ({
                          value: project.projectNumber,
                          text: project.projectNumber,
                        }))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b
                      className={`${
                        !currentCustomer.settings?.ludvig_service_ek_project ||
                        !isUserTeamOwner(currentCustomer, currentUser)
                          ? " text-muted"
                          : ""
                      }`}
                    >
                      <Tooltip content="LUDVIG_SERVICE_ARA_DESCRIPTION">
                        <Info16Filled className="mr-sm" />
                      </Tooltip>
                      {translate("ARA")}
                    </b>
                  </td>
                  <td>
                    <Checkbox
                      disabled={disableAssignmentYearEndCheckboxes()}
                      onChange={(_, { checked }) =>
                        checked
                          ? openModal(
                              CustomerDetailsPanelModalState.ENABLE_ARA_MODAL
                            )
                          : openModal(
                              CustomerDetailsPanelModalState.DISABLE_ARA_MODAL
                            )
                      }
                      checked={
                        currentCustomer.settings?.ludvig_service_ara || false
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b
                      className={`${
                        disableAssignmentYearEndCheckboxes()
                          ? " text-muted"
                          : ""
                      }`}
                    >
                      <Tooltip content="LUDVIG_SERVICE_YEAR_END_START_DESCRIPTION">
                        <Info16Filled className="mr-sm" />
                      </Tooltip>
                      {translate("YEAR_END_START")}
                    </b>
                  </td>

                  <td>
                    <div className="d-flex">
                      <Checkbox
                        disabled={disableAssignmentYearEndCheckboxes()}
                        onChange={(_, checked) =>
                          checked
                            ? openModal(
                                CustomerDetailsPanelModalState.ENABLE_YEAREND_START_MODAL
                              )
                            : openModal(
                                CustomerDetailsPanelModalState.DISABLE_YEAREND_START_MODAL
                              )
                        }
                        checked={
                          currentCustomer.settings
                            ?.ludvig_service_year_end_start || false
                        }
                      />

                      {customerLudvigServicesYEStart &&
                        isUserTeamOwner(currentCustomer, currentUser) && (
                          <Button
                            onClick={() =>
                              navigate({
                                pathname:
                                  AppRouteHelper.getCustomerLudvigServiceFeedback(
                                    currentCustomer.customer_number,
                                    "YEAR_END_START"
                                  ),
                              })
                            }
                            className="ms-2"
                            size="sm"
                          >
                            {translate("GIVE_FEEDBACK")}
                          </Button>
                        )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b
                      className={`${
                        disableAssignmentYearEndCheckboxes()
                          ? " text-muted"
                          : ""
                      }`}
                    >
                      <Tooltip content="LUDVIG_SERVICE_YEAR_END_FINISH_DESCRIPTION">
                        <Info16Filled className="mr-sm" />
                      </Tooltip>
                      {translate("YEAR_END_FINISH")}
                    </b>
                  </td>

                  <td>
                    <div className="d-flex">
                      <Checkbox
                        disabled={disableAssignmentYearEndCheckboxes()}
                        onChange={(_, checked) =>
                          checked
                            ? openModal(
                                CustomerDetailsPanelModalState.ENABLE_YEAREND_FINISH_MODAL
                              )
                            : openModal(
                                CustomerDetailsPanelModalState.DISABLE_YEAREND_FINISH_MODAL
                              )
                        }
                        checked={
                          currentCustomer.settings
                            ?.ludvig_service_year_end_finish || false
                        }
                      />
                      {customerLudvigServicesYEFinish &&
                        isUserTeamOwner(currentCustomer, currentUser) && (
                          <Button
                            onClick={() =>
                              navigate({
                                pathname:
                                  AppRouteHelper.getCustomerLudvigServiceFeedback(
                                    currentCustomer.customer_number,
                                    "YEAR_END_FINISH"
                                  ),
                              })
                            }
                            className="ms-2"
                            size="sm"
                          >
                            {translate("GIVE_FEEDBACK")}
                          </Button>
                        )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}

        <h6 className="pt-xl">{translate("ACTIVE_ASSIGNMENT")}</h6>
        <table className="padding-sm">
          <tbody>
            <tr className="no-border">
              <td>
                <b>{translate("ASSIGNMENT_MANAGER")}</b>
              </td>
              <td>
                <div className="d-flex flex-wrap fg-2">
                  {customerManagersEmailsUnique &&
                    customerManagersEmailsUnique.map((email) => (
                      <Persona key={email} userId={email} />
                    ))}
                </div>
              </td>
            </tr>
            <tr className="no-border">
              <td>
                <b>{translate("CUSTOMER_DETAILS.SALES_MANAGER")}</b>
              </td>
              <td>
                <div className="d-flex flex-wrap fg-2">
                  {currentCustomer.salesperson_email && (
                    <Persona
                      key={currentCustomer.salesperson_email}
                      userId={currentCustomer.salesperson_email}
                    />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <b>{translate("FREQUENCY")}</b>
              </td>
              <td>{translate(currentCustomer.getRecurrence() || "UNKNOWN")}</td>
            </tr>
            <tr>
              <td>
                <b>{translate("ASSIGNMENT_VALUE")}</b>
              </td>
              <td>
                {currentCustomer.getAssignmentValue() ? (
                  <Input
                    type="number"
                    placeholder={translate("ASSIGNMENT_VALUE")}
                    disabled
                    defaultValue={currentCustomer.getAssignmentValue()}
                  />
                ) : (
                  translate("UNKNOWN")
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {customerState.isLoading && (
          <Spinner size={SpinnerSize.ExtraSmall} className="mt-4" />
        )}

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState === CustomerDetailsPanelModalState.DISABLE_ARA_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_ARA"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_ara: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_ARA" />}
        >
          <p>{translate("CONFIRM_DISABLE_ARA")}</p>
        </Modal>
        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState === CustomerDetailsPanelModalState.ENABLE_ARA_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_ARA"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_ara: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_ARA" />}
        >
          <p>{translate("CONFIRM_ENABLE_ARA")}</p>
        </Modal>
        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.ENABLE_YEAREND_START_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_start: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_YEAR_END_START" />}
        >
          <p>{translate("CONFIRM_ENABLE_YEAR_END_START")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.DISABLE_YEAREND_START_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_start: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_YEAR_END_START" />}
        >
          <p>{translate("CONFIRM_DISABLE_YEAR_END_START")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.ENABLE_YEAREND_FINISH_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="ENABLE_YEAR_END_FINISH"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_finish: true,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
            />
          }
          header={<ModalHeader headerTitleText="ENABLE_YEAR_END_FINISH" />}
        >
          <p>{translate("CONFIRM_ENABLE_YEAR_END_FINISH")}</p>
        </Modal>

        <Modal
          onDismiss={() => closeModal()}
          isOpen={
            modalState ===
            CustomerDetailsPanelModalState.DISABLE_YEAREND_FINISH_MODAL
          }
          footer={
            <ModalFooter
              labelSubmit="DISABLE_YEAR_END_START"
              labelCancel="CANCEL"
              onSave={() => {
                handleUpdateCustomerSettings({
                  ludvig_service_year_end_finish: false,
                });
                closeModal();
              }}
              onCancel={() => closeModal()}
              saveButtonVariant="danger"
            />
          }
          header={<ModalHeader headerTitleText="DISABLE_YEAR_END_FINISH" />}
        >
          <p>{translate("CONFIRM_DISABLE_YEAR_END_FINISH")}</p>
        </Modal>
      </DrawerBody>
    </OverlayDrawer>
  );
}
import { Card } from "@fluentui/react-components";
import { Image48Regular } from "@fluentui/react-icons";
import { format, parseISO } from "date-fns";

import { decodeHtmlEntity } from "libs/decode-html-entity";
import { IntranetPost } from "models/intranet";
import { useEffect, useRef, useState } from "react";

interface Props {
  post: IntranetPost;
}

function IntranetPostCard({ post }: Props) {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const image = imageRef.current;
    if (image && image.complete) {
      handleImageLoad();
    }
  }, []);

  const handleImageLoad = () => {
    if (imageRef.current && imageRef.current.height > 0) {
      setShowPlaceholder(false);
    }
  };

  return (
    <Card
      className="w-100 h-100"
      onClick={() => window.open(post.yoast_head_json.og_url, "_blank")}
    >
      {showPlaceholder ? (
        <div className="placeholder-post-image">
          <Image48Regular />
        </div>
      ) : null}
      <img
        ref={imageRef}
        src={post.yoast_head_json.og_image[0].url}
        alt=""
        className="post-image"
        style={{ display: showPlaceholder ? "none" : "block" }}
        onLoad={handleImageLoad}
        onError={() => setShowPlaceholder(true)}
      />
      <h6 className="mb-sm fw-700">{decodeHtmlEntity(post.title.rendered)}</h6>
      <div className="d-flex">
        <p className="m-0 pr-md color-primary fw-600">
          {format(
            parseISO(post.yoast_head_json.article_published_time),
            "dd/MM/yyyy"
          )}
        </p>
        <p className="m-0 color-primary fw-400">
          {post.yoast_head_json.author}
        </p>
      </div>
      <p
        className="m-0 truncated-text"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: decodeHtmlEntity(post.excerpt.rendered),
        }}
      />
    </Card>
  );
}

export default IntranetPostCard;

/* eslint-disable camelcase */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startOfMonth } from "date-fns";

import { useStickyState, useStickyStateForDate, useTranslation } from "hooks";
import {
  fetchServiceTypes,
  setTimelineWindow,
  setTimelineWindowFromLocalStorage,
  setActivitiesTimelineWindow,
  getActivitiesTeam,
  fetchUnfinishedActivities,
  fetchActivitiesForCustomers,
  getOnedriveShortcutFolder,
} from "state/activities/actions";
import { CustomerSearchByEnum } from "api/customers";
import { AppDispatch } from "state/use-app-redux";
import { RootState } from "state";
import {
  fetchCustomers,
  fetchSearchCustomers,
  setIsSearchResult,
} from "state/customers/actions";
import {
  ActivityDefinition,
  ActivityStatus,
  Recurrence,
} from "models/activities/activity";
import { UserSnapshot } from "models/user";
import { useCustomerServiceFilter } from "helpers/conveyorBelt/serviceFilter";
import * as ServiceHelpers from "constants/conveyorBeltConsts";
import { AssignmentManagementContextProvider } from "views/conveyorBelt/context/AssignmentManagementContext";
import "./index.scss";
import {
  SearchByEnum,
  TabViewValueEnum,
} from "constants/enums/conveyorBelts.enum";
import {
  generateDeadlineOptions,
  RecurrenceTypes,
  ServiceTypesList,
} from "helpers/conveyorBelt/conveyorBeltHelpers";
import AssignmentManagementHeader from "./components/overview/components/AssignmentManagementHeader";
import AssignmentManagementBody from "./components/overview/components/AssignmentManagementBody";
import { CustomersContextProvider } from "./context/CustomersContext";

export default function AssignmentManagement() {
  const { translate, language } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const { data: customers, isSearchResult } = useSelector(
    (state: RootState) => state.customers
  );
  const {
    data: activitiesData,
    isLoading: isActivitiesLoading,
    hasLoaded: hasLoadedActivities,
    activitiesTeams: {
      data: activitiesTeams,
      isLoading: isActivitiesTeamsLoading,
    },
    unfinishedActivities: {
      data: unfinishedActivities,
      isLoading: isUnfinishedActivitiesLoading,
    },
    definitions: { data: definitions, dataMap: definitionsMap },
  } = useSelector((state: RootState) => state.activities);

  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [searchByAssignee, setSearchByAssignee] = useState("");
  const [disableSort, setDisableSort] = useState(true);

  const [selectedTabView, setSelectedTabView] = useStickyState<string>(
    ServiceHelpers.SELECTED_TAB_VIEW,
    TabViewValueEnum.ALL
  );
  const [selectedActivityFilterFrequencies, setActivityFilterFrequencies] =
    useStickyState<Recurrence[]>(
      ServiceHelpers.FILTER_ACTIVITY_FREQUENCIES,
      []
    );
  const [selectedDate, setSelectedDate] = useStickyStateForDate(
    ServiceHelpers.FILTER_SELECTED_DATE
  );
  const [selectedActivityFilterServiceTypes, setActivityFilterServiceTypes] =
    useStickyState<string[]>(ServiceHelpers.FILTER_ACTIVITY_SERVICE_TYPES, []);
  const [selectedActivityFilterDeadline, setActivityFilterDeadline] =
    useStickyState<string | null>(
      ServiceHelpers.FILTER_ACTIVITY_DEADLINE,
      null
    );
  const [selectedActivityFilterDefinitions, setActivityFilterDefinitions] =
    useStickyState<ActivityDefinition[]>(
      ServiceHelpers.FILTER_ACTIVITY_DEFINITIONS,
      []
    );
  const [selectedActivityFilterStatuses, setActivityFilterStatuses] =
    useStickyState<ActivityStatus[]>(
      ServiceHelpers.FILTER_ACTIVITY_STATUSES,
      []
    );
  const [selectedActivityFilterUserIds, setActivityFilterUserIds] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_ACTIVITY_USERS_IDS,
      []
    );
  const [selectedNextActivityFilterUsers, setNextActivityFilterUsers] =
    useStickyState<UserSnapshot[]>(
      ServiceHelpers.FILTER_NEXT_ACTIVITY_USERS,
      []
    );
  const [overdueFilterEnabled, setEnabledOverdueFilter] =
    useStickyState<boolean>(ServiceHelpers.FILTER_OVERDUE_ENABLED, false);
  const [handedDocs, setHandedDocs] = useStickyState(
    ServiceHelpers.SORT_HANDED_DOCS,
    false
  );
  const [incompleteFilterEnabled, setIncompleteFilterEnabled] =
    useStickyState<boolean>(ServiceHelpers.FILTER_INCOMPLETE_ENABLED, false);
  const [isCacheDisabled, setIsCacheDisabled] = useStickyState<boolean>(
    ServiceHelpers.ACTIVITIES_CACHE_DISABLED,
    false
  );
  const [searchBy, setSearchBy] = useStickyState<string>(
    ServiceHelpers.SEARCH_BY,
    SearchByEnum.MyCustomers
  );

  const allCustomers = customers.map(
    (customersState) => customersState.customer
  );

  const customerNumbers = allCustomers.map(
    (customer) => customer.customer_number
  );

  const resetTimeWindowComplete = () => {
    if (selectedDate) {
      setSelectedDate(undefined);
      dispatch(setActivitiesTimelineWindow({}));
    }
  };

  const setTimeWindowAt = (date: Date | null | undefined) => {
    const newDate = date ? startOfMonth(date) : undefined;
    setSelectedDate(newDate);

    if (newDate != null) {
      dispatch(setActivitiesTimelineWindow({ months: [newDate] }));
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (customerNumbers.length) {
      if (activitiesData.length === 0 && !isActivitiesLoading) {
        dispatch(fetchActivitiesForCustomers({ customerNumbers }));
      }

      if (unfinishedActivities.length === 0 && !isUnfinishedActivitiesLoading) {
        dispatch(fetchUnfinishedActivities(customerNumbers));
      }

      if (activitiesTeams.length === 0 && !isActivitiesTeamsLoading) {
        dispatch(getActivitiesTeam(customerNumbers));
      }
    }
  }, [customers]);

  useEffect(() => {
    dispatch(getOnedriveShortcutFolder());
  }, [dispatch]);

  useEffect(() => {
    if (selectedDate != null) {
      dispatch(setTimelineWindow([selectedDate]));
      dispatch(setActivitiesTimelineWindow({ months: [selectedDate] }));
    } else if (activitiesData.length === 0 && !hasLoadedActivities) {
      dispatch(setTimelineWindowFromLocalStorage());
    }
    dispatch(fetchServiceTypes());
  }, [selectedDate, dispatch]);

  // deadline
  const deadlineOptions = generateDeadlineOptions(translate, 5);

  // frequency
  const frequencyOptions = RecurrenceTypes.filter(
    (recurrence) => recurrence !== Recurrence.Unknown
  ).map((recurrence) => ({
    value: recurrence,
    text: translate(recurrence),
  }));

  const serviceTypesOptions = ServiceTypesList.map((service) => ({
    value: service,
    text: translate(service),
  }));

  // statuses
  const statusOptions = [
    {
      value: ServiceHelpers.ACTIVITY_STATUS_PASSED_DEADLINE_KEY,
      text: translate("PASSED_DEADLINE"),
    },
    {
      value: ActivityStatus.ManagerSupport,
      text: translate(ActivityStatus.ManagerSupport),
    },
    {
      value: ActivityStatus.SupportSentToClient,
      text: translate(ActivityStatus.SupportSentToClient),
    },
    {
      value: ActivityStatus.SupportNeeded,
      text: translate(ActivityStatus.SupportNeeded),
    },
    {
      value: ActivityStatus.SupportFinished,
      text: translate(ActivityStatus.SupportFinished),
    },
  ];

  // activity definition
  const activityTypeOptions = definitions
    .filter(
      (definition, index, array) =>
        array.findIndex(
          (def) => def.activity_name === definition.activity_name
        ) === index
    )
    .map((definition) => ({
      text: definition.getTranslation(language),
      value: definition.title,
      data: definition,
    }))
    .sort((a, b) => (a.text < b.text ? -1 : 1));

  const searchCustomers = useCallback(() => {
    dispatch(
      fetchSearchCustomers(customerSearchTerm, CustomerSearchByEnum.CUSTOMER)
    ).then(() => dispatch(setIsSearchResult(true)));
  }, [customerSearchTerm, dispatch]);

  useEffect(() => {
    if (searchByAssignee) {
      dispatch(
        fetchSearchCustomers(searchByAssignee, CustomerSearchByEnum.ASSIGNEE)
      ).then(() => dispatch(setIsSearchResult(true)));
    }
  }, [dispatch, searchByAssignee]);

  useEffect(() => {
    if (
      Boolean(selectedDate) &&
      selectedActivityFilterServiceTypes.length > 0
    ) {
      setDisableSort(false);
    } else {
      setDisableSort(true);
    }
  }, [selectedDate, selectedActivityFilterServiceTypes]);

  const { servicesFilter, isFiltering } = useCustomerServiceFilter({
    filterOverdue: overdueFilterEnabled,
    filterActive: incompleteFilterEnabled,
    filterStatuses: selectedActivityFilterStatuses,
    filterRecurrence: selectedActivityFilterFrequencies,
    filterDefinitions: selectedActivityFilterDefinitions,
    filterUserIds: selectedActivityFilterUserIds,
    filterServiceTypes: selectedActivityFilterServiceTypes,
    filterByMonth: selectedDate,
    filterByDeadline: selectedActivityFilterDeadline,
    filterNextUserIds: selectedNextActivityFilterUsers,
    definitionsMap,
  });

  useEffect(() => {
    if (
      Boolean(selectedDate) &&
      selectedActivityFilterServiceTypes.length > 0
    ) {
      setDisableSort(false);
    } else {
      setDisableSort(true);
    }
  }, [selectedDate, selectedActivityFilterServiceTypes]);

  useEffect(() => {
    if (
      customerNumbers.length > 0 &&
      activitiesData.length === 0 &&
      !isActivitiesLoading
    ) {
      dispatch(fetchActivitiesForCustomers({ customerNumbers }));
      dispatch(fetchUnfinishedActivities(customerNumbers));
    }
  }, [isCacheDisabled]);

  const filtersPropsList = {
    selectedActivityFilterFrequencies,
    setActivityFilterFrequencies,
    selectedDate,
    setSelectedDate,
    selectedActivityFilterServiceTypes,
    setActivityFilterServiceTypes,
    selectedActivityFilterDeadline,
    setActivityFilterDeadline,
    selectedActivityFilterDefinitions,
    setActivityFilterDefinitions,
    selectedActivityFilterStatuses,
    setActivityFilterStatuses,
    selectedActivityFilterUserIds,
    setActivityFilterUserIds,

    selectedNextActivityFilterUsers,
    setNextActivityFilterUsers,

    overdueFilterEnabled,
    setEnabledOverdueFilter,
    resetTimeWindowComplete,
    setTimeWindowAt,

    handedDocs,
    setHandedDocs,
    disableSort,
    setDisableSort,

    deadlineOptions,
    frequencyOptions,
    serviceTypesOptions,
    statusOptions,
    activityTypeOptions,
  };

  const handleSearchBySelect = (optionValue?: string) => {
    if (optionValue === undefined) {
      return;
    }

    setSearchBy(optionValue);
    setCustomerSearchTerm("");
    setSearchByAssignee("");

    if (isSearchResult) {
      fetchMyCustomers();
    }
  };
  let debounceTimer: ReturnType<typeof setTimeout>;

  const handleOnSearchInputChange = (
    nativeEvent: MouseEvent | Event,
    value: string
  ): void => {
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(() => {
      setCustomerSearchTerm(value);
    }, 500);

    if (
      searchBy === SearchByEnum.AllCustomers &&
      nativeEvent instanceof PointerEvent &&
      nativeEvent.pointerType === "mouse" &&
      nativeEvent.type === "click" &&
      value === "" &&
      isSearchResult
    ) {
      fetchMyCustomers();
    }

  };

  const setSearchToMyCustomers = () => {
    setSearchBy(SearchByEnum.MyCustomers);
    setCustomerSearchTerm("");
    fetchMyCustomers();
  };

  const fetchMyCustomers = () => {
    dispatch(fetchCustomers()).then(() => dispatch(setIsSearchResult(false)));
  };

  return (
    <AssignmentManagementContextProvider
      servicesFilter={servicesFilter}
      isCacheDisabled={isCacheDisabled}
      setIsCacheDisabled={setIsCacheDisabled}
      isFiltering={isFiltering}
      setSearchToMyCustomers={setSearchToMyCustomers}
    >
      <CustomersContextProvider
        servicesFilter={servicesFilter}
        handedDocs={handedDocs}
        customerSearchTerm={customerSearchTerm}
        searchBy={searchBy}
      >
        <AssignmentManagementHeader
          filtersPropsList={filtersPropsList}
          selectedTabView={selectedTabView}
          setSelectedTabView={setSelectedTabView}
          setIncompleteFilterEnabled={setIncompleteFilterEnabled}
          searchBy={searchBy}
          searchByAssignee={searchByAssignee}
          setSearchByAssignee={setSearchByAssignee}
          handleSearchBySelect={handleSearchBySelect}
          fetchMyCustomers={fetchMyCustomers}
          customerSearchTerm={customerSearchTerm}
          searchCustomers={searchCustomers}
          handleOnSearchInputChange={handleOnSearchInputChange}
        />
        <AssignmentManagementBody
          selectedActivityFilterUsers={selectedActivityFilterUserIds}
          isCollapsedView={selectedTabView === TabViewValueEnum.COLLAPSED}
          incompleteFilterEnabled={incompleteFilterEnabled}
        />
      </CustomersContextProvider>
    </AssignmentManagementContextProvider>
  );
}
/* eslint-disable camelcase */
import { plainToClass } from "class-transformer";

import AppConfig from "app-config";
import {
  CreateCustomer,
  CustomersLudvigServiceStatus,
  Customer,
  CustomerSettings,
  SieFile,
  VariableKeyObject,
} from "models/customer";
import { Assignment } from "models/assignment";
import { PersonDetails } from "models/profile";
import { LudvigServiceFeedback } from "models/ludvigAutomationService";
import { Billing } from "models/offer/Billing";
import { DealCustomer } from "models/offer/Customer";
import { normalizeOrgNrAndSSN } from "libs/number-format";
import DealContactDetails from "models/offer/DealContactDetails";
import { getAuthorizationHeader } from "./libs/auth-header";
import { ResponseError } from "./libs/responseError";

export enum CustomerSearchByEnum {
  CUSTOMER = "CUSTOMER",
  ASSIGNEE = "ASSIGNEE",
}

export class CustomersAPI {
  static async fetchCustomerByOrgNumber(
    token: string,
    customerId: string,
    query?: URLSearchParams
  ) {
    const trimmedCustomerId = customerId.replaceAll(/\D+/g, "");
    const queryString = query ? `?${query.toString()}` : "";

    const response = await fetch(
      `${AppConfig.API_URL}/customers/${trimmedCustomerId}${queryString}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as Customer;
    return plainToClass(Customer, json, { exposeDefaultValues: true });
  }

  static async createLudvigServiceFeedback(
    token: string,
    customer: Customer,
    ludvigServiceFeedback: LudvigServiceFeedback
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer.customer_number}/ludvig_service_feedback`,
      {
        method: "POST",
        body: JSON.stringify(ludvigServiceFeedback),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async updateCustomerSettings(
    token: string,
    customerNumber: string,
    update: Partial<CustomerSettings>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/settings`,
      {
        method: "PATCH",
        body: JSON.stringify(update),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async createCustomer(token: string, customer: CreateCustomer) {
    const response = await fetch(`${AppConfig.API_URL}/customers`, {
      method: "POST",
      body: JSON.stringify(customer),
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async setAssignmentManagers(
    token: string,
    customerId: string,
    assignmentId: string,
    managers: PersonDetails[]
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/assignment/${assignmentId}`,
      {
        method: "PATCH",
        body: JSON.stringify({
          managers,
        }),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Assignment[];
    return jsonArray.map((json) =>
      plainToClass(Assignment, json, { exposeDefaultValues: true })
    );
  }

  static async createCustomerTeam(token: string, customerId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/teams/customers/${customerId}/team_v2`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async updateCustomer(
    token: string,
    customerId: string,
    update: Partial<Customer>
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}`,
      {
        method: "PATCH",
        body: JSON.stringify(update),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return true;
  }

  static async fetchCustomerAssignments(token: string, customerId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/assignments`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Assignment[];
    return jsonArray.map((json) =>
      plainToClass(Assignment, json, { exposeDefaultValues: true })
    );
  }

  static async searchCustomer(token: string, searchString: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/?search_term=${searchString}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const jsonArray = (await response.json()) as Customer[];
    return jsonArray.map((json) =>
      plainToClass(Customer, json, { exposeDefaultValues: true })
    );
  }

  static async fetchCustomers(
    token: string,
    params?: {
      searchTerm?: string;
      searchBy?: CustomerSearchByEnum;
      populate?: string;
      skipServices?: string;
    }
  ) {
    const query = new URLSearchParams();
    query.set("version", "2");

    if (params?.searchTerm) {
      let { searchTerm } = params;

      // The BE requires the search term to be encoded
      // if searching by customer
      if (params?.searchBy === CustomerSearchByEnum.CUSTOMER) {
        searchTerm = encodeURIComponent(searchTerm);
      }

      query.set("search_term", searchTerm);
    }

    if (params?.searchBy) {
      query.set("search_by", params.searchBy);
    }

    if (params?.populate) {
      query.set("populate_empty", params.populate);
    }

    if (params?.skipServices) {
      query.set("skip_ludvig_services", params.skipServices);
    }

    const response = await fetch(`${AppConfig.API_URL}/customers?${query}`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const jsonArray = (await response.json()) as Customer[];
    const result = jsonArray.map((json) =>
      plainToClass(Customer, json, { exposeDefaultValues: true })
    );
    return result;
  }

  static async fetchCustomerById(token: string, customerId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw ResponseError(response);
    }

    const json = (await response.json()) as Customer;
    return plainToClass(Customer, json, { exposeDefaultValues: true });
  }

  static async getCustomerSieFiles(token: string, customer_number: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer_number}/sie-files`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const jsonArray = await response.json();
    const asArray = jsonArray.sie_files as SieFile[];
    const asSieFileArray = asArray.map((json) => plainToClass(SieFile, json));
    return asSieFileArray;
  }

  static async updateCustomerSieFilePriority(
    token: string,
    sie_bucket: string,
    sie_key: string,
    customer_id: string
  ) {
    const sie_id_complete = sie_key.replace(/.*\//i, "");
    const sie_id = sie_id_complete.replace(/[a-zA-Z.]/i, "");
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customer_id}/sie-file/${sie_id}/prioritize`,
      {
        method: "POST",
        body: JSON.stringify({ bucket: sie_bucket, key: sie_key }),
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async fetchCustomerBilling(
    token: string,
    organizationNumber: string
  ): Promise<Billing> {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${organizationNumber}/billings`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as any;
    return json;
  }

  static async fetchCustomerContacts(token: string, customerId: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerId}/pog_contacts`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return (await response.json()) as DealContactDetails[];
  }

  static async createOrUpdateContact(
    token: string,
    contactData: DealContactDetails,
    organizationNumber?: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${organizationNumber}/pog_contacts`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactData),
      }
    );

    if (!response.ok) {
      throw ResponseError(response);
    }

    const json = (await response.json()) as DealContactDetails;

    return plainToClass(DealContactDetails, json, {
      exposeDefaultValues: true,
    });
  }

  static async createOrUpdateCustomer(
    token: string,
    dealCustomerData: DealCustomer
  ) {
    const tempDealCustomerData = {
      ...dealCustomerData,
      org_number: normalizeOrgNrAndSSN(dealCustomerData.org_number),
    };

    const response = await fetch(
      `${AppConfig.API_URL}/customers/create_or_update`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tempDealCustomerData),
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as DealCustomer;

    return plainToClass(DealCustomer, json, {
      exposeDefaultValues: true,
    });
  }

  static async fetchCustomersTeamsStatuses(
    token: string,
    customerNumbers: string[]
  ) {
    const customersString = String(customerNumbers);
    const response = await fetch(
      `${AppConfig.API_URL}/teams/customers/team?customer_numbers=${customersString}`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const json = (await response.json()) as VariableKeyObject;
    return json;
  }

  static async fetchTeamsFilesTabLink(token: string, customerNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/teams/customers/${customerNumber}/team/driveurl`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const filesLink = await response.text();
    return filesLink;
  }

  static async fetchCustomersLudvigServices(
    token: string,
    customerNumbers: string[]
  ) {
    const customersBody = JSON.stringify({
      customer_ids: customerNumbers,
    });
    const response = await fetch(
      `${AppConfig.API_URL}/customers/ludvig-services`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: customersBody,
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const result = (await response.json()) as CustomersLudvigServiceStatus[];
    return result;
  }

  static async fetchCustomerDeferredPayments(
    token: string,
    customerNumber: string
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/deferred_payments `,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const jsonArray = await response.json();
    return jsonArray;
  }

  static async updateCustomerDeferredPayments(
    token: string,
    customerNumber: string,
    postponedInvoiceData: JSON
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/deferred_payments `,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
        body: JSON.stringify(postponedInvoiceData),
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return true;
  }

  static async deleteCustomerContact(
    token: string,
    contactEmail: string,
    customerNumber: string
  ) {
    const response = await fetch(`${AppConfig.API_URL}/contacts/remove`, {
      method: "POST",
      headers: {
        ...getAuthorizationHeader(token),
      },
      body: JSON.stringify({
        customer_number: customerNumber,
        contact_email: contactEmail,
      }),
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }
    return true;
  }

  static async addTemporaryAccessToCustomer(
    token: string,
    customerId: string,
    isSupportUser: boolean
  ) {
    const response = await fetch(
      `${AppConfig.API_URL}/teams/customers/${customerId}/temporary_member_team_v2?is_support=${isSupportUser}`,
      {
        method: "POST",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );
    if (!response.ok) {
      throw new Error(await response.text());
    }
  }

  static async clearCustomerCache(token: string, customerNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${customerNumber}/cache`,
      {
        method: "DELETE",
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return true;
  }

  static async searchCustomerBySSN(token: string, ssnNumber: string) {
    const response = await fetch(
      `${AppConfig.API_URL}/customers/${ssnNumber}/coi`,
      {
        headers: {
          ...getAuthorizationHeader(token),
        },
      }
    );

    if (!response.ok) {
      throw ResponseError(response);
    }

    const json = await response.json();
    return json;
  }
}

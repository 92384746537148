import { IntranetAPI } from "api/intranet";
import { authMethod } from "auth";
import { IntranetPost } from "models/intranet";
import { MyThunkAction } from "state";
import { appendError } from "state/notifications";
import {
  IntranetPostsAction,
  LOADING_INTRANET_POSTS,
  LOADING_INTRANET_POSTS_FAILED,
  SET_INTRANET_POSTS,
} from ".";

export function setIntranetPosts(
  intranetPosts: IntranetPost[]
): IntranetPostsAction {
  return {
    type: SET_INTRANET_POSTS,
    payload: intranetPosts,
  };
}

export function setLoadingIntranetPosts(
  isLoading: boolean
): IntranetPostsAction {
  return {
    type: LOADING_INTRANET_POSTS,
    payload: isLoading,
  };
}

export function loadingIntranetPostsFailed(): IntranetPostsAction {
  return {
    type: LOADING_INTRANET_POSTS_FAILED,
  };
}

export function getIntranetPosts(): MyThunkAction {
  return async (dispatch) => {
    try {
      dispatch(setLoadingIntranetPosts(true));
      const token = await authMethod.getStoredAccessToken();
      const intranetPosts = await IntranetAPI.getIntranetPosts(token);

      dispatch(setIntranetPosts(intranetPosts));
    } catch (e) {
      dispatch(appendError("INTRANET.LOADING_FAILED", e as Error));
      dispatch(loadingIntranetPostsFailed());
    } finally {
      dispatch(setLoadingIntranetPosts(false));
    }
  };
}

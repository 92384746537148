import { IntranetPost } from "models/intranet";

export const SET_INTRANET_POSTS = "intranet/SET_INTRANET_POSTS";
export const LOADING_INTRANET_POSTS = "intranet/LOADING_INTRANET_POSTS";
export const LOADING_INTRANET_POSTS_FAILED =
  "intranet/LOADING_INTRANET_POSTS_FAILED";

export interface SetIntranetPosts {
  type: typeof SET_INTRANET_POSTS;
  payload: IntranetPost[];
}

export interface LoadingIntranetPosts {
  type: typeof LOADING_INTRANET_POSTS;
  payload: boolean;
}

export interface LoadingIntranetPostsFailed {
  type: typeof LOADING_INTRANET_POSTS_FAILED;
}

export type IntranetPostsAction =
  | SetIntranetPosts
  | LoadingIntranetPosts
  | LoadingIntranetPostsFailed;

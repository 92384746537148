/* eslint-disable no-continue */
/* eslint-disable camelcase */
import { Tab, TabList } from "@fluentui/react-components";
import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import PendingChangesModal from "components/modal/PendingChangesModal";
import Tooltip from "components/tooltip/Tooltip";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import {
  TOGGLE,
  TOGGLE_INT,
  TOGGLE_MULTI_SELECT,
  TOGGLE_SINGLE_SELECT,
  TOGGLE_SINGLE_SELECT_LOOKUP,
  TOGGLE_STRING,
} from "constants/offer/input-form-types";
import { ACCOUNTING_SERVICE_LINE } from "constants/servicesConsts";
import { useTranslation } from "hooks/use-translate";
import { getMonthsOfOneYear } from "libs/date/generate-month";
import { isValidRegex } from "libs/is-valid-regex";
import { uniqByFilter } from "libs/uniq-filter";
import {
  Service,
  ServiceCategory,
  ServiceGroupItem,
  getServiceCategoryFromEconomyServiceArea,
} from "models/offer/ServiceLine";
import { ServiceValidity } from "models/offer/ServiceValidity";
import { RootState } from "state";
import { updateCurrentOffer } from "state/offer/offersSlice";
import { updateServicesAndPostDealData } from "state/offer/offersThunks";
import { useAppDispatch } from "state/use-app-redux";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { useGetBreadcrumbs } from "views/createNew/offer/components/wizardSection/useGetBreadcrumbs";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import ServicesFormData from "views/createNew/offer/wizard/Services/ServicesFormData";
import ServiceDetailsHeader, {
  FREQUENCY_ARRAY,
  Frequency,
} from "views/createNew/offer/wizard/components/service-details-header";
import "../Services/ServiceForm.scss";
import { ServicesContext } from "../Services/ServicesContextProvider";
import FinalPriceModal from "./FinalPriceModal";

import ServiceColumn, { ColSize } from "../components/service-column";
import ServiceRow from "../components/service-row";
import "./currentAccounting.scss";

type CurrentAccountingForm = ServicesFormData & {
  yearEndYear: string | null;
  yearEndMonth: string;
  finalPrice: number;
  services: Service[];
};

export const SERVICE_GROUP_CURRENT_ACCOUNTING = "Current accounting";
const SERVICE_CATEGORY = SERVICE_GROUP_CURRENT_ACCOUNTING;

const defaultStartDate = getMonthsOfOneYear()[0];

export default function CurrentAccounting() {
  const { translate, ts } = useTranslation();
  const { getBreadcrumbs } = useGetBreadcrumbs();

  const navigate = useNavigate();
  const { edit } = useParams<{ edit: string }>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { updateServiceGroups } = useContext(ServicesContext);

  const serviceAreas = useSelector(
    (state: RootState) => state.offers.offerTemplate.data?.service_areas
  );
  if (!serviceAreas) {
    throw new Error("Missing service areas");
  }

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  const user = useSelector((state: RootState) => state.users.currentUser);

  const { service_areas: serviceAreasFromOffer } = currentOffer;

  const routing = useRoutingForOffer(location.pathname, currentOffer);
  const initialCurrentOffer = useRef(currentOffer);
  const [isDirty, setIsDirty] = useState(false);
  const [modalOpen, setOpenModal] = useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);

  const currentAccountingTemplate =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      SERVICE_GROUP_CURRENT_ACCOUNTING,
      SERVICE_CATEGORY,
      serviceAreas
    ) ?? new ServiceCategory();

  const currentAccountingInCurrentOffer =
    getServiceCategoryFromEconomyServiceArea(
      ACCOUNTING_SERVICE_LINE,
      SERVICE_GROUP_CURRENT_ACCOUNTING,
      SERVICE_CATEGORY,
      serviceAreasFromOffer
    ) ?? new ServiceCategory();

  const serviceHeaders = uniqByFilter(
    currentAccountingTemplate.services.map((service) => service.header)
  );
  const servicesForHeader = (header: string) =>
    Service.sortServices(
      currentAccountingTemplate.services.filter(
        (service) => service.header === header
      )
    );
  const groupedServices: ServiceGroupItem[] = serviceHeaders
    .map((header) => ({
      header,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sortingNumber: currentAccountingTemplate.services.find(
        (service) => service.header === header
      )!.pog_header_sorting_number,
      services: servicesForHeader(header),
    }))
    .sort((a, b) => a.sortingNumber - b.sortingNumber);

  const [selectedHeader, setSelectedHeader] = useState(
    groupedServices[0]?.header
  );

  const {
    start_date,
    end_date,
    project_manager,
    frequency,
    year_end_year,
    year_end_month,
    final_price,
    services: servicesInOffer,
  } = currentAccountingInCurrentOffer;

  const [currentAccountingData, setCurrentAccountingData] =
    useState<CurrentAccountingForm>({
      startDate: start_date || defaultStartDate,
      endDate: end_date,
      projectManager: project_manager || user.email,
      yearEndYear: year_end_year,
      yearEndMonth: year_end_month || "12",
      frequency: frequency || currentAccountingTemplate.frequency,
      finalPrice: final_price,
      services: [],
    });

  const activeServices = servicesInOffer.length
    ? servicesInOffer
    : currentAccountingTemplate.services.filter(
        (service) => service.input_form_type_state
      );
  const [currentAccountingServices, setCurrentAccountingServices] = useState<
    Service[]
  >([...activeServices]);

  const [servicesValidity, setServicesValidity] = useState<ServiceValidity[]>(
    []
  );

  const isValid = () => {
    return servicesValidity.every(
      (sv) => sv.isValueValid && sv.isFrequencyValid
    );
  };

  useEffect(() => {
    setServicesValidity(
      currentAccountingServices.map((service) => {
        const isFrequencyValid = service.is_pog_service_frequency
          ? !!service.pog_service_frequency
          : true;

        let isValueValid = true;

        switch (service.input_form_type) {
          case TOGGLE_SINGLE_SELECT:
          case TOGGLE_MULTI_SELECT:
          case TOGGLE_SINGLE_SELECT_LOOKUP:
            isValueValid = !!service.input_form_type_value;
            break;

          case TOGGLE_INT:
            if (!service.input_form_type_value) {
              isValueValid = false;
            } else {
              isValueValid = validateNumericInput(
                service.input_form_type_value,
                service
              );
            }
            break;

          case TOGGLE_STRING:
            if (!service.input_form_type_value) {
              isValueValid = false;
            } else {
              isValueValid = validateTextInput(
                service.input_form_type_value,
                service
              );
            }
            break;

          default:
        }

        return {
          serviceMatrixId: service.service_matrix_id,
          serviceHeader: service.header,
          isValueValid,
          isFrequencyValid,
        };
      })
    );

    updateHeaderFrequency();

    // Sets the highest Frequency occurrence found in all the services.
    // "highest" in a way that it occurs more frequently.
    function updateHeaderFrequency() {
      let headerFreq = "";

      currentAccountingServices
        .map((service) => service.pog_service_frequency)
        .filter((serviceFreq) => serviceFreq)
        .forEach((serviceFreq) => {
          if (!headerFreq) {
            headerFreq = serviceFreq;
          } else {
            const headerFreqIndex = FREQUENCY_ARRAY.indexOf(
              headerFreq as Frequency
            );
            const serviceFreqIndex = FREQUENCY_ARRAY.indexOf(
              serviceFreq as Frequency
            );

            if (serviceFreqIndex < headerFreqIndex) {
              headerFreq = serviceFreq;
            }
          }
        });

      // The greatest allowed Frequency occurrence in the header is 'quarterly'.
      if (headerFreq === "yearly") {
        headerFreq = "quarterly";
      }

      // The lowest allowed Frequency occurrence in the header is 'monthly'.
      // 'monthly' is also the default value, should for some reason the value be empty.
      if (!headerFreq || headerFreq === "weekly") {
        headerFreq = "monthly";
      }

      setCurrentAccountingData((data) => {
        if (data.frequency !== headerFreq) {
          setIsDirty(true);

          return {
            ...data,
            frequency: headerFreq as Frequency,
          };
        }

        return data;
      });
    }
  }, [currentAccountingServices]);

  const validateNumericInput = (value: string, service: Service) => {
    if (!service.input_form_type_content || !value) {
      return true;
    }

    const splitContent = service.input_form_type_content.split(":");

    if (splitContent.length !== 2) {
      throw new Error(
        "'input_form_type_content' has an unexpected format coming from the Backend"
      );
    }

    const min = splitContent[0];
    const max = splitContent[1];

    let isMinValid = true;
    let isMaxValid = true;

    if (min) {
      const minParsed = parseInt(min, 10);

      if (Number.isNaN(minParsed)) {
        throw new Error(
          "'input_form_type_content' has an unexpected min value coming from the Backend"
        );
      }

      isMinValid = parseInt(value, 10) >= minParsed;
    }

    if (max) {
      const maxParsed = parseInt(max, 10);

      if (Number.isNaN(maxParsed)) {
        throw new Error(
          "'input_form_type_content' has an unexpected max value coming from the Backend"
        );
      }

      isMaxValid = parseInt(value, 10) <= maxParsed;
    }

    return isMinValid && isMaxValid;
  };

  const validateTextInput = (value: string, service: Service) => {
    if (!service.input_form_type_content || !value) {
      return true;
    }

    const regex = isValidRegex(service.input_form_type_content);
    if (!regex) {
      throw new Error(
        "'input_form_type_content' has an unexpected RegExp format coming from the Backend"
      );
    }

    return regex.test(value);
  };

  const isServiceToggled = (service: Service) => {
    return currentAccountingServices.some(
      (toggledServices) =>
        toggledServices.service_matrix_id === service.service_matrix_id
    );
  };

  const handleOnBeforePrevious = () => {
    if (isDirty) {
      setOpenModal(true);
      return;
    }

    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
  };

  const handleDiscard = () => {
    dispatch(updateCurrentOffer(initialCurrentOffer.current));
    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const updateServiceContext = () => {
    const serviceGroupToUpdate =
      getServiceLineWithCurrentAccountingData().service_areas[0].service_lines[0].service_groups.find(
        (group) => group.name === SERVICE_GROUP_CURRENT_ACCOUNTING
      );

    if (serviceGroupToUpdate) {
      updateServiceGroups(serviceGroupToUpdate);
    }
  };

  const handleSave = async () => {
    await dispatch(
      updateServicesAndPostDealData(getServiceLineWithCurrentAccountingData())
    );

    updateServiceContext();

    setIsDirty(false);
    setOpenModal(false);
    if (routing.previous?.path) {
      navigate(routing.previous.path);
    }
    if (edit !== undefined) {
      navigate(-1);
    }
  };

  const saveOffer = async (newPrice = 0) => {
    setPriceModalOpen(false);

    const newServiceCategoryData = {
      ...currentAccountingData,
      finalPrice: newPrice,
    };

    updateServiceCategoryData(newServiceCategoryData);

    await dispatch(
      updateServicesAndPostDealData(
        getServiceLineWithCurrentAccountingData(newServiceCategoryData)
      )
    );
  };

  const handlePriceCancelModal = (newPrice: number) => {
    saveOffer(newPrice);
    setIsDirty(false);
  };

  const handleProceedToSummary = async (newPrice: number) => {
    await saveOffer(newPrice);
    updateServiceContext();
    navigate(OfferRouteHelper.getSummary());
  };

  const getServiceLineWithCurrentAccountingData = (
    data = currentAccountingData
  ) => {
    // Save the data to the service line in deal object
    const newCurrentAccountingData: ServiceCategory = {
      ...currentAccountingInCurrentOffer,
      project_manager: data.projectManager,
      start_date: data.startDate,
      frequency: data.frequency,
      final_price: data.finalPrice,
      year_end_year: data.yearEndYear,
      year_end_month: data.yearEndMonth,
      services: currentAccountingServices,
    };
    const newServiceAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      serviceAreasFromOffer,
      newCurrentAccountingData
    );

    return { service_areas: newServiceAreas };
  };

  const updateServiceCategoryData = (
    formHeaderData: Partial<CurrentAccountingForm>
  ) => {
    const newCurrentAccountingFormData = {
      ...currentAccountingData,
      ...formHeaderData,
    };
    setCurrentAccountingData(newCurrentAccountingFormData);
    const updatedServiceLine = getServiceLineWithCurrentAccountingData(
      newCurrentAccountingFormData
    );
    dispatch(updateCurrentOffer(updatedServiceLine));
    setIsDirty(true);
  };

  const renderServicesForHeader = (colSize: ColSize[]) => {
    const group = groupedServices.find((g) => g.header === selectedHeader);
    if (!group) {
      return;
    }

    /**
     * Iterates over services in the selected group, identifying the first
     * with an invalid value or, failing that, the first with an invalid frequency.
     *
     * Returns the matrix ID of the first invalid service, or undefined.
     */
    function getFirstInvalidServiceInputId() {
      for (const service of group!.services) {
        const serviceValidity = servicesValidity.find(
          (sv) => sv.serviceMatrixId === service.service_matrix_id
        );

        if (!serviceValidity) {
          continue;
        }

        const serviceWithValueIsPresent = currentAccountingServices.some(
          (s) =>
            s.header === group!.header &&
            isServiceToggled(s) &&
            s.input_form_type !== TOGGLE
        );

        if (serviceWithValueIsPresent) {
          if (!serviceValidity.isValueValid) {
            return service.service_matrix_id;
          }

          continue;
        }

        if (!serviceValidity.isFrequencyValid) {
          return service.service_matrix_id;
        }
      }
    }

    return (
      <Row>
        <Col className="px-lg">
          <Row className="fw-semibold mb-4">
            <ServiceColumn colSize={colSize[0]}>
              {translate("ON_OFF")}
            </ServiceColumn>
            <ServiceColumn colSize={colSize[1]}>
              {translate("SERVICE")}/{translate("SETTINGS")}
            </ServiceColumn>
            <ServiceColumn colSize={colSize[2]}>
              {translate("VALUE")} *
            </ServiceColumn>
            <ServiceColumn colSize={colSize[3]}>
              {translate("FREQUENCY")} *
            </ServiceColumn>
            <ServiceColumn colSize={colSize[4]}>
              {translate("RESPONSIBLE")}
            </ServiceColumn>
          </Row>
          {!!servicesValidity.length &&
            group.services.map((service, index, services) => {
              const isLast = services.length === index + 1;

              const activeService = currentAccountingServices.find(
                (currService) =>
                  currService.service_matrix_id === service.service_matrix_id
              );

              return (
                <ServiceRow
                  key={`${service.header}${service.name}`}
                  colSize={colSize}
                  isLast={isLast}
                  service={activeService || service}
                  offerApprovedByEmail={
                    currentAccountingInCurrentOffer.approved_by_email
                  }
                  serviceIsInitiallyActive={
                    service.input_form_type_state && service.input_is_disabled
                  }
                  serviceIsToggled={isServiceToggled(service)}
                  triggerInputFocus={
                    getFirstInvalidServiceInputId() ===
                    service.service_matrix_id
                  }
                  setIsDirty={setIsDirty}
                  setCurrentAccountingServices={setCurrentAccountingServices}
                  validateNumericInput={validateNumericInput}
                  validateTextInput={validateTextInput}
                />
              );
            })}
        </Col>
      </Row>
    );
  };

  const serviceFormHeader = (
    <ServiceDetailsHeader
      startDateOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          startDate: value,
        });
      }}
      startDate={currentAccountingData.startDate}
      projectManagerOnChange={(personId) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          projectManager: personId ?? "",
        });
      }}
      projectManager={currentAccountingData.projectManager}
      frequencyOnChange={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          frequency: value,
        });
      }}
      frequency={currentAccountingData.frequency}
      yearEndData={currentAccountingData}
      yearEndInCurrentOffer={currentAccountingInCurrentOffer}
      updateServiceCategoryData={(value) => {
        updateServiceCategoryData({
          ...currentAccountingData,
          yearEndMonth: value,
        });
      }}
    />
  );

  const serviceFormContent = (
    <>
      <PendingChangesModal
        isOpen={modalOpen}
        onCancel={() => setOpenModal(false)}
        onSave={() => handleSave()}
        onDiscard={() => handleDiscard()}
        isValid={
          !!currentAccountingServices.length &&
          !!currentAccountingData.frequency
        }
        isLoading={loadingStatus === LoadingStatusEnum.PENDING}
      />

      <div className="current-accounting-content mb-3">
        <Row className="current-accounting-service-header align-items-center mb-lg">
          <Col md="auto">{translate("SERVICE_HEADER")}:</Col>
          <Col>
            {groupedServices.length > 0 && (
              <TabList
                className="p-0 fw-semibold"
                selectedValue={selectedHeader}
                onTabSelect={(e, data) =>
                  setSelectedHeader(data.value as string)
                }
              >
                {groupedServices.map((headerData) => {
                  const { header } = headerData;

                  const isRequired = servicesValidity.some(
                    (sv) =>
                      sv.serviceHeader === header &&
                      (!sv.isValueValid || !sv.isFrequencyValid)
                  );

                  return (
                    <Tooltip
                      content={`${isRequired ? "* " : ""}${header}`}
                      notTranslatable
                      childrenClassName="current-accounting-tab"
                    >
                      <Tab
                        className="current-accounting-tab-content"
                        value={header}
                        key={header}
                      >
                        {`${isRequired ? "* " : ""}${header}`}
                      </Tab>
                    </Tooltip>
                  );
                })}
              </TabList>
            )}
          </Col>
        </Row>

        {renderServicesForHeader([70, undefined, "3", "3", "2"])}
      </div>
      {priceModalOpen && (
        <FinalPriceModal
          finalPrice={currentAccountingInCurrentOffer.final_price}
          setPriceModalOpen={setPriceModalOpen}
          handlePriceCancelModal={handlePriceCancelModal}
          handleProceedToSummary={handleProceedToSummary}
        />
      )}
    </>
  );

  function renderServiceForm() {
    return (
      <WizardSection
        serviceName={ts(ACCOUNTING_SERVICE_LINE)}
        subtitles={[translate("CURRENT_ACCOUNTING_SUBTITLE")]}
        loadingStatus={loadingStatus}
        nextButtonText={translate("ADD_FINAL_PRICE_FOR_DEAL")}
        isNextHidden={
          !currentAccountingServices.length ||
          !currentAccountingData.frequency ||
          !isValid()
        }
        onBeforePrevious={handleOnBeforePrevious}
        isRoutingButton={false}
        onNext={() => setPriceModalOpen(true)}
        hasDivider
        content={serviceFormHeader}
      >
        {serviceFormContent}
      </WizardSection>
    );
  }

  return edit ? (
    <Modal
      className="service-modal"
      isOpen={edit !== undefined}
      onDismiss={() => {
        if (isDirty) {
          setOpenModal(true);
          return;
        }
        setOpenModal(false);
        navigate(-1);
      }}
      size="fit-content-min"
      header={
        <ModalHeader
          headerTitleContent={
            <div className="header-blue-stripe d-flex align-items-center">
              <Breadcrumb
                className="ml-sm"
                items={
                  getBreadcrumbs(
                    ts(ACCOUNTING_SERVICE_LINE),
                    true
                  ) as BreadcrumbDefinitionItem[]
                }
              />
            </div>
          }
        />
      }
      footer={
        <ModalFooter
          labelSubmit="ADD_FINAL_PRICE_FOR_DEAL"
          onSave={() => setPriceModalOpen(true)}
          isDisabled={
            !currentAccountingServices.length ||
            !currentAccountingData.frequency ||
            !isValid()
          }
          isLoading={loadingStatus === LoadingStatusEnum.PENDING}
        />
      }
    >
      <>
        <div className="contentHeader">{serviceFormHeader}</div>
        {serviceFormContent}
      </>
    </Modal>
  ) : (
    renderServiceForm()
  );
}

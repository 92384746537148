import Modal from "components/modal";
import { ModalFooter } from "components/modal/ModalFooter";
import { ModalHeader } from "components/modal/ModalHeader";
import { useTranslation } from "hooks";

type FrequencyWarningDialogProps = {
  onDiscard: () => void;
  onSave: () => void;
};

export default function FrequencyWarningDialog({
  onDiscard,
  onSave,
}: FrequencyWarningDialogProps) {
  const { translate } = useTranslation();

  const handleOnDiscard = () => {
    onDiscard();
  };

  return (
    <Modal
      className="top-modal"
      onDismiss={handleOnDiscard}
      isOpen
      header={<ModalHeader headerTitleText="WRONG_FREQUENCY_MODAL.HEADER" />}
      footer={
        <ModalFooter
          onCancel={handleOnDiscard}
          onSave={onSave}
          labelCancel="CANCEL"
          labelSubmit="CONTINUE"
        />
      }
    >
      <div className="d-flex py-lg">
        <span>{translate("WRONG_FREQUENCY_MODAL.CONTENT")}</span>
      </div>
    </Modal>
  );
}

import { Spinner } from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import Breadcrumb, { BreadcrumbDefinitionItem } from "components/breadcrumb";
import {
  LayoutBody,
  LayoutHeader,
  LayoutHeaderLeft,
} from "components/layout/Layout";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { Activity } from "models/activities/activity";
import { AppRouteHelper, CustomerDetailsParams } from "routes";
import { RootState } from "state";
import {
  EditActivityStatus,
  fetchOptionalActivities,
  updateOptionalServiceActivities,
} from "state/activities/actions";
import { fetchCustomerDeliveryPlan } from "state/customers/actions";
import { ActivitiesDetailsPanel } from "../serviceActivities/components/ActivitiesDetailsPanel";
import { EditActivitiesTable } from "./components/EditActivitiesTable";

export default function EditCustomerAssignments() {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showActivitiesPanel, setShowActivitiesPanel] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState<
    Activity | undefined
  >();

  const { customerId } = useParams<CustomerDetailsParams>();

  const customers = useSelector((state: RootState) => state.customers);
  const customerState = customers.data.find(
    (customer) => customer.customer.customer_number === customerId
  );
  const customer = customerState?.customer;

  const activities = useSelector((state: RootState) => state.activities);
  const { optionalActivities } = activities;

  useEffect(() => {
    if (!customer || customerState.hasLoadedDeliveryPlan) {
      return;
    }

    dispatch(fetchOptionalActivities(customer));
    dispatch(fetchCustomerDeliveryPlan({ customer }));
  }, [dispatch, customerState?.hasLoadedDeliveryPlan, customer]);

  const navigateBack = (focusClient = false) => {
    if (customer) {
      navigate(
        AppRouteHelper.getAssignments(
          focusClient ? customer.customer_number : ""
        )
      );
    }
  };

  const breadcrumbs: BreadcrumbDefinitionItem[] = [
    {
      text: translate("MY_ASSIGNMENTS"),
      onClick: () => navigateBack(),
    },
    {
      text: customer?.name ?? <Spinner />,
      onClick: () => navigateBack(true),
    },
    { text: translate("EDIT_ACTIVITIES") },
  ];

  const updateActivities = (
    toAdd: EditActivityStatus[],
    toRemove: EditActivityStatus[],
    isOnetime: boolean,
    toAddStartDate?: Date,
    toRemoveEndDate?: Date,
    serviceBoxId?: string
  ) => {
    if (!customer) {
      return;
    }
    dispatch(
      updateOptionalServiceActivities(
        customer,
        toAdd,
        toRemove,
        isOnetime,
        serviceBoxId,
        toAddStartDate,
        toRemoveEndDate
      )
    );
  };

  const openActivityDetailsPanel = (activity: Activity) => {
    if (showActivitiesPanel) {
      setShowActivitiesPanel(false);
      setSelectedActivity(undefined);
    } else {
      setShowActivitiesPanel(true);
      setSelectedActivity(activity);
    }
  };

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <div className="d-flex  align-items-center justify-content-between">
            <div className="pr-md">
              <h1>{translate("ADD_OPTIONAL_ACTIVITIES")}</h1>
            </div>
          </div>
        </LayoutHeaderLeft>
      </LayoutHeader>
      <LayoutBody>
        <Breadcrumb className="mb-sm" items={breadcrumbs} />

        <Row className="p-md fpx-14">
          {translate("OPTIONAL_ACTIVITIES_ONLY")}
        </Row>

        {!customer ? (
          translate("MISSING_CUSTOMER")
        ) : (
          <>
            <div>
              {optionalActivities.data.length > 0 && (
                <EditActivitiesTable
                  disabled={optionalActivities.isLoading}
                  showFrequency
                  openActivityDetailsPanel={openActivityDetailsPanel}
                  onSave={updateActivities}
                  customer={customer}
                  customerOptionalActivitiesLoading={
                    optionalActivities.isLoading || activities.isLoading
                  }
                  customerOptionalActivities={optionalActivities.data}
                />
              )}
            </div>

            {(customerState.isLoading || activities.isLoading) && (
              <Spinner
                label={translate("LOADING_ACTIVITIES")}
                size={SpinnerSize.ExtraSmall}
              />
            )}
          </>
        )}

        <ActivitiesDetailsPanel
          showActivitiesPanel={showActivitiesPanel}
          selectedActivity={selectedActivity}
          onDismiss={() => setShowActivitiesPanel(false)}
        />
      </LayoutBody>
    </>
  );
}

import { IntranetPost } from "models/intranet";
import {
  IntranetPostsAction,
  LOADING_INTRANET_POSTS,
  LOADING_INTRANET_POSTS_FAILED,
  SET_INTRANET_POSTS,
} from ".";

export type IntranetPostsState = {
  data: IntranetPost[];
  isLoading: boolean;
  hasFailed: boolean;
  hasLoaded: boolean;
};

const initialIntranetPostsState: IntranetPostsState = {
  data: [],
  isLoading: false,
  hasFailed: false,
  hasLoaded: false,
};

export function intranetPostsReducer(
  state = initialIntranetPostsState,
  action: IntranetPostsAction
) {
  switch (action.type) {
    case SET_INTRANET_POSTS: {
      return {
        ...state,
        data: action.payload,
        hasLoaded: true,
      };
    }
    case LOADING_INTRANET_POSTS: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case LOADING_INTRANET_POSTS_FAILED: {
      return {
        ...state,
        data: [],
        isLoading: false,
        hasLoaded: true,
        hasFailed: true,
      };
    }

    default: {
      return state;
    }
  }
}

import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "@fluentui/react-components";
import { Dismiss16Regular, Checkmark16Regular } from "@fluentui/react-icons";

import { useTranslation } from "hooks/use-translate";
import { searchSSNType } from "./index";

type Props = {
  content?: searchSSNType[];
};
export function ResponseTable({ content }: Props) {
  const { translate } = useTranslation();

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHeaderCell colSpan={3}>
            {translate("SYSTEM_TABLE_LABEL")}
          </TableHeaderCell>
          <TableHeaderCell colSpan={3}>
            {translate("EXISTS_TABLE_LABEL")}
          </TableHeaderCell>
          <TableHeaderCell colSpan={8}>
            {" "}
            {translate("COMMENT_TABLE_LABEL")}
          </TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {content?.map((item: searchSSNType) => (
          <TableRow>
            <TableCell colSpan={3}>{item.system}</TableCell>
            <TableCell colSpan={3}>
              {item.ssn_exists ? (
                <span>
                  <Checkmark16Regular />
                  <span className="ml-xs">{translate("YES")}</span>
                </span>
              ) : (
                <span>
                  <Dismiss16Regular />
                  <span className="ml-xs">{translate("NO")}</span>
                </span>
              )}
            </TableCell>
            <TableCell colSpan={8}>{item.comment}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

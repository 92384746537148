import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { TransparentAccordion } from "components/accordion/transparentAccordion";
import { useTranslation } from "hooks/use-translate";
import { numberAsSwedishCurrency } from "libs/number-format";
import { useServiceMatrix } from "libs/service-matrix";
import {
  sortDealsByChangedDate,
  sortOffersByChangedDate,
} from "libs/sort-deals-by-date";
import { Offer } from "models/offer/SavedOffers";
import { Service } from "models/offer/ServiceLine";
import { RootState } from "state";
import { SearchBy, fetchCompanyInfoFromSales } from "state/offer/companyThunks";
import { fetchDeals } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import OfferItem from "views/createNew/offer/components/offers/components/offerItem";
import DownloadPdfButton from "views/createNew/offer/wizard/components/downloadPdfButton";

type Props = {
  customerId: string;
  expanded: boolean | undefined;
};

export default function CustomerPageContracts({ customerId, expanded }: Props) {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();
  const { data: offers, createdDeals } = useSelector(
    (state: RootState) => state.offers
  );

  const {
    GetServicesGroupedByHeader,
    GetTotalIndexedPrice,
    GetCurrentPricingYear,
  } = useServiceMatrix();

  useEffect(() => {
    const init = async () => {
      const company = await dispatch(
        fetchCompanyInfoFromSales({
          searchByParam: SearchBy.CustomerNumber,
          customerId,
        })
      ).unwrap();

      if (company && company.org_number) {
        dispatch(fetchDeals(company.org_number));
      }
    };

    init();
  }, [customerId, dispatch]);

  const filteredAndSortedContracts = sortOffersByChangedDate(
    offers.filter((o: Offer) => o.envelopes && o.envelopes?.length > 0)
  ).sort((a: Offer, b: Offer) => (!a.envelopes && !!b.envelopes ? -1 : 1));

  const filteredDeals = sortDealsByChangedDate(
    createdDeals.data.filter((d) => d.state === "contract_signed")
  );

  const renderServiceByHeader = (services: Service[]) => {
    return services.map((serviceByHeader: Service, index) => (
      <span
        key={`${serviceByHeader.units}${serviceByHeader.id}`}
        className={index > 0 ? "servicesGroupedByHeaderItem" : ""}
      >
        {serviceByHeader.units > 1 ? `${serviceByHeader.units}x ` : null}
        {serviceByHeader.name}
      </span>
    ));
  };

  return (
    <TransparentAccordion
      title={translate("OFFER_CONTRACTS_LABEL")}
      loadingStatus={createdDeals.status}
      expanded={expanded}
      subtitle={`(${filteredAndSortedContracts.length + filteredDeals.length})`}
    >
      {filteredAndSortedContracts.length + filteredDeals.length ? (
        <div className="p-lg cs-content">
          <table className="table table-hover justify-content-between table-md customerSettingsContractsTable">
            <thead>
              <tr>
                <th>{translate("OFFER_ID_ALT")}</th>
                <th>
                  <Row>
                    <Col xs={4}>{translate("SERVICE_TYPE_ALT")}</Col>
                    <Col xs={8}>{translate("SERVICES_ALT")}</Col>
                  </Row>
                </th>
                <th> {translate("INDEXED_PRICE")}</th>
              </tr>
            </thead>
            <tbody>
              {filteredDeals.length > 0 &&
                filteredDeals.map((deal) => (
                  <tr
                    key={`dealItemForID-${deal.id}-${deal.created_date}`}
                    data-testid="deal-list"
                    className={`${
                      deal.state === "offer" && "draftOfferListRow"
                    }`}
                  >
                    <td>{deal?.id || "-"}</td>
                    <td>
                      {GetServicesGroupedByHeader({
                        offer: deal,
                        groupByNameToo: true,
                      }).map((item) => (
                        <Row key={`${deal.id}${item.header}`}>
                          <Col xs={4}>{item.header}</Col>
                          <Col xs={8}>
                            {renderServiceByHeader(item.services)}
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td>
                      {`${numberAsSwedishCurrency(
                        GetTotalIndexedPrice(deal)
                      )}  ${
                        GetCurrentPricingYear(deal) !== ""
                          ? `(${GetCurrentPricingYear(deal)})`
                          : ""
                      }`}
                    </td>
                    <td>
                      {" "}
                      <DownloadPdfButton dealId={deal.id} type="link" />
                    </td>
                  </tr>
                ))}

              {filteredAndSortedContracts.length > 0 &&
                filteredAndSortedContracts.map((o: Offer, index) => (
                  <tr key={`offerItemForID-${o.offer_id}`}>
                    <td colSpan={3}>
                      <OfferItem offer={o} />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-lg cs-content">
          <span>{translate("CUSTOMER_NO_DEALS")}</span>
        </div>
      )}
    </TransparentAccordion>
  );
}

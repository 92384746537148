import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  ArrowRight20Regular,
  SlideAdd16Regular,
  Dismiss32Regular,
} from "@fluentui/react-icons";
import { Button, Spinner } from "@fluentui/react-components";

import AppConfig from "app-config";
import { useAuth } from "auth/use-auth";
import { RootState } from "state";
import { getIntranetPosts } from "state/intranet/actions";
import {
  LayoutBody,
  LayoutFooter,
  LayoutHeader,
  LayoutHeaderLeft,
  LayoutHeaderRight,
} from "components/layout/Layout";
import { TextLink } from "components/textLink/textLink";
import { useTranslation } from "hooks/use-translate";
import { UserRoles } from "models/user";
import { useGetCompanyTypesQuery } from "api/ludvigApi";
import { CreatePogOfferFromHSDealModal } from "./components/CreatePogOfferFromHSDealModal";
import IntranetPostCard from "./components/IntranetPostCard";
import "./index.scss";

export function HomePage() {
  const { translate } = useTranslation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const [openCreatePogOfferDialog, setOpenCreatePogOfferDialog] =
    useState(false);

  const intranetPosts = useSelector((state: RootState) => state.intranetPosts);

  const isPogContractCreationAllowed = () => {
    return user?.roles?.includes(UserRoles.USERS_CENTRAL_SALES);
  };

  // call this RTK query to get the customer-types
  // the response is cached so it wont be called again when we enter the POG flow for example
  useGetCompanyTypesQuery();

  useEffect(() => {
    if (intranetPosts.data.length === 0 && !intranetPosts.isLoading) {
      dispatch(getIntranetPosts());
    }
  }, [dispatch, intranetPosts]);

  return (
    <>
      <LayoutHeader>
        <LayoutHeaderLeft>
          <h1>{translate("HOME")}</h1>
        </LayoutHeaderLeft>
        <LayoutHeaderRight>
          <TextLink url="https://konsultsupport.ludvig.se/support/tickets/new">
            {translate("CREATE_NEW_SUPPORTTICKET")}
          </TextLink>
        </LayoutHeaderRight>
      </LayoutHeader>
      <LayoutBody>
        <div className="d-flex flex-column mittludvig-home h-100 pb-lg">
          <div className="linear-gradient d-flex justify-content-between align-items-center h-100 max-height-300">
            <div className="d-flex flex-column justify-content-center align-items-center w-100 p-lg">
              <div className="text-white headline-one">
                {translate("WELCOME_BACK_MITTLUDVIG")}
              </div>
              <div className="d-flex pt-md">
                <Button
                  className="color-white"
                  appearance="outline"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/redovisningsprocessen/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<ArrowRight20Regular />}
                  iconPosition="before"
                >
                  {translate("ACCOUNTING_PROCESS")}
                </Button>
                <Button
                  className="color-white mx-xxl"
                  appearance="outline"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/process-loneregistrering/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<ArrowRight20Regular />}
                  iconPosition="before"
                >
                  {translate("PAYROLL_PROCESS")}
                </Button>
                <Button
                  className="color-white"
                  appearance="outline"
                  href="https://intranet.ludvig.se/vart-arbetssatt/kundprocessen/ekonomi/bokslutsprocessen/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<ArrowRight20Regular />}
                  iconPosition="before"
                >
                  {translate("YEAR_END_PROCESS")}
                </Button>
                {isPogContractCreationAllowed() && (
                  <>
                    <div className="vertical-divider mr-md ml-lg mt-xs" />
                    <Button
                      className="border-white bg-transparent"
                      onClick={() => setOpenCreatePogOfferDialog(true)}
                    >
                      <span className="d-flex flex-row color-white">
                        <span className="px-2">
                          {translate("HS_TO_POG_DEAL")}
                        </span>
                        <SlideAdd16Regular />
                      </span>
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column h-100">
            <div className="d-flex justify-content-between w-100 pt-lg pb-md">
              <h6 className="m-0">
                {translate("INTRANET.LATEST_IN_MITTLUDVIG")}
              </h6>
              <a
                href="https://intranet.ludvig.se/affarsomraden/ekonomi/program-inom-ekonomi/how-to-play/mittludvig/"
                className="blue-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translate("INTRANET.SEE_MORE")}
              </a>
            </div>
            <Row className="h-100">
              {intranetPosts.isLoading && (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner label={translate("INTRANET.LOADING")} />
                </div>
              )}
              {!intranetPosts.isLoading && intranetPosts.hasLoaded
                ? intranetPosts.data.slice(0, 3).map((post) => (
                    <Col xs={4}>
                      <IntranetPostCard post={post} />
                    </Col>
                  ))
                : !intranetPosts.isLoading && (
                    <div className="d-flex align-items-center justify-content-center h-100 flex-column">
                      <Dismiss32Regular className="color-gray500 mb-sm" />
                      <p className="m-0 color-gray500 body-italic-light fpx-20">
                        {translate("INTRANET.NO_RESULTS")}
                      </p>
                    </div>
                  )}
            </Row>
          </div>
        </div>
      </LayoutBody>
      <LayoutFooter>
        <div className="footer pl-lg d-flex align-items-center">
          <div className="title">{translate("FOOTER.SUPPORT")}</div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("PHONE_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_PHONE}</span>
          </div>
          <div className="footer-vertical-divider ml-md p-sm" />
          <div>
            <span className="support-label mr-xs">
              {translate("EMAIL_SHORT")}:
            </span>
            <span className="support-value">{AppConfig.SUPPORT_EMAIL}</span>
          </div>
        </div>
      </LayoutFooter>
      {openCreatePogOfferDialog && (
        <CreatePogOfferFromHSDealModal
          onDismiss={() => setOpenCreatePogOfferDialog(false)}
        />
      )}
    </>
  );
}

import { ChevronRight20Regular } from "@fluentui/react-icons";
import { Fade } from "react-bootstrap";
import { useSelector } from "react-redux";

import { RootState } from "state";
import { useTranslation } from "hooks";
import PendingChangesModal from "components/modal/PendingChangesModal";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { TaxObjectsList } from "views/createNew/offer/wizard/IncomeTax/TaxObjectsList";
import { Service } from "models/offer/ServiceLine";
import { TaxObjectDetails, TaxObjectState } from "models/offer/TaxObject";
import { TaxObjectService } from "models/offer/TaxObjectService";

type Props = {
  modalOpen: boolean;
  setOpenModal: (modalOpen: boolean) => void;
  showServiceDetails: boolean;
  setShowServiceDetails: (showServiceDetails: boolean) => void;
  handleSave: () => void;
  handleDiscard: () => void;
  isValid: boolean;
  showTaxObjects: boolean;
  setShowTaxObjects: (showTaxObjects: boolean) => void;
  renderServiceDetailsForm: () => JSX.Element;
  incomeTaxServices: Service[];
  saveFormData: (
    taxObject: TaxObjectDetails,
    taxObjectServices: TaxObjectService[],
    smallBusinessServices?: Service[]
  ) => void;
  label?: string;
  smallBusinessServices?: Service[];
};

export default function ServiceFormWithTaxObjects({
  modalOpen,
  setOpenModal,
  showServiceDetails,
  setShowServiceDetails,
  handleSave,
  handleDiscard,
  isValid,
  showTaxObjects,
  setShowTaxObjects,
  renderServiceDetailsForm,
  incomeTaxServices,
  saveFormData,
  label,
  smallBusinessServices,
}: Props) {
  const { translate } = useTranslation();

  const { data: currentOffer, status: loadingStatus } = useSelector(
    (state: RootState) => state.offers.currentOffer
  );
  if (!currentOffer) {
    throw new Error("No active offer");
  }

  const { customer } = currentOffer;
  const customerTaxObjects = customer?.tax_objects ?? [];

  const taxObjectsToShow = customerTaxObjects.filter(
    (taxObj) => taxObj.state !== TaxObjectState.INACTIVE
  );

  return (
    <>
      <PendingChangesModal
        isOpen={modalOpen}
        onCancel={() => setOpenModal(false)}
        onSave={() => handleSave()}
        onDiscard={() => handleDiscard()}
        isValid={isValid}
        isLoading={loadingStatus === LoadingStatusEnum.PENDING}
      />
      <Fade
        in={showServiceDetails}
        mountOnEnter
        unmountOnExit
        onExited={() => {
          setShowTaxObjects(true);
          setShowServiceDetails(false);
        }}
      >
        <>
          <div className="d-flex flex-row flex justify-content-end">
            <div className="p-md">
              <span className="text-gray">
                {translate("TAX_OBJECTS_COUNT", [
                  taxObjectsToShow.length.toString(),
                ])}
              </span>
            </div>
            <div className="m-md vertical-divider" />
            <div
              className="p-md d-flex pog-link cursor-pointer align-items-center"
              onClick={() => {
                setShowServiceDetails(false);
                setShowTaxObjects(true);
              }}
            >
              <span>{translate("TAX_OBJECTS_TITLE")}</span>
              <ChevronRight20Regular className="ml-sm" />
            </div>
          </div>
          {renderServiceDetailsForm()}
        </>
      </Fade>
      <Fade
        in={showTaxObjects}
        appear
        mountOnEnter
        unmountOnExit
        onExited={() => {
          setShowServiceDetails(true);
          setShowTaxObjects(false);
        }}
      >
        <TaxObjectsList
          customerTaxObjects={taxObjectsToShow}
          taxServiceObjects={currentOffer.tax_objects}
          incomeTaxServices={incomeTaxServices}
          onDismiss={() => {
            setShowTaxObjects(false);
            setShowServiceDetails(true);
          }}
          onSave={(taxObject, taxObjectServices) =>
            saveFormData(taxObject, taxObjectServices, smallBusinessServices)
          }
          label={label}
        />
      </Fade>
    </>
  );
}
import { CheckmarkRegular, Info20Regular } from "@fluentui/react-icons";
import React, { HTMLProps, useState } from "react";

import Tooltip from "components/tooltip/Tooltip";
import Switch from "components/switch";
import { useTranslation } from "hooks";
import "./ServicePicker.scss";
import { isToggleDisabled } from "./ServicePickerHelper";

export type ServiceToggle = {
  label: string;
  value: string;
  enabled: boolean;
  onToggle: () => void;
  isPackage: boolean;
};

type ServicePickerProps = {
  name: string;
  enabled: boolean;
  onToggle: () => void;

  serviceToggles: ServiceToggle[];

  description?: string;
} & HTMLProps<HTMLDivElement>;

export function ServicePicker({
  name,
  enabled,
  serviceToggles,
  description = "",
  className = "",
  onToggle,
  disabled = false,
  ...rest
}: ServicePickerProps) {
  const { ts } = useTranslation();

  const [selectedService, setSelectedService] = useState("");

  return (
    <Tooltip
      content={!selectedService ? "CLICK_TO_EXPAND" : "CLICK_TO_COLLAPSE"}
    >
      <div
        className={`service-wrapper ${className} ${enabled && "selected"}`}
        {...rest}
      >
        <div className="service-selected">
          <CheckmarkRegular />
        </div>

        <div
          className={`service${description === "" ? " no-description" : ""}`}
          onClick={() => {
            if (selectedService === name) {
              setSelectedService("");
            } else {
              setSelectedService(name);
            }
            onToggle();
          }}
        >
          <div className="text-center service-heading">{ts(name)}</div>
          {description !== "" && (
            <>
              <hr />
              <div className="text-center service-description px-md">
                {!enabled ? description : ts("CHOSE_SERVICE")}
              </div>
            </>
          )}
        </div>

        {serviceToggles.length > 0 && (
          <div className="service-toggles">
            {serviceToggles.map((serviceToggle, index) => {
              const showHrDivider =
                serviceToggle.isPackage &&
                serviceToggles[index - 1] &&
                !serviceToggles[index - 1].isPackage ? (
                  <div className="horizontal-divider m-xs" />
                ) : null;
              return (
                <React.Fragment key={serviceToggle.value}>
                  {showHrDivider}
                  <div
                    key={serviceToggle.value}
                    className="d-flex justify-content-space-between"
                  >
                    <Switch
                      key={serviceToggle.value}
                      className="service-toggle"
                      label={ts(serviceToggle.label)}
                      labelPosition="after"
                      checked={serviceToggle.enabled}
                      disabled={
                        isToggleDisabled(serviceToggle.label, serviceToggles) ||
                        disabled
                      }
                      onToggleMethod={(e) => {
                        e.stopPropagation();
                        serviceToggle.onToggle();
                      }}
                    />
                    <Tooltip
                      content={ts(
                        `OFFER.SERVICE_PICKER.TOOLTIP_${serviceToggle.label}`
                      )}
                      notTranslatable
                    >
                      <Info20Regular className="text-muted my-auto mx-0" />
                    </Tooltip>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        )}
      </div>
    </Tooltip>
  );
}

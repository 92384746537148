import { ReactNode, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import copy from "copy-to-clipboard";
import {
  Copy16Regular,
  ErrorCircle20Regular,
  Info20Regular,
} from "@fluentui/react-icons";

import { SolidAccordion } from "components/accordion/solidAccordion";
import { TooltipInfoIcon } from "components/icon";
import Modal from "components/modal";
import { ModalHeader } from "components/modal/ModalHeader";
import { ModalFooter } from "components/modal/ModalFooter";
import { OverlaySpinner } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import {
  canUploadPhysicalDeal,
  isDealEditable,
} from "libs/customerdeal-helpers";
import { isPlaceholderEmail } from "libs/generate-placeholder-email";
import {
  FinalPriceComparisonType,
  useServiceMatrix,
} from "libs/service-matrix";
import { Appendix } from "models/offer/CustomerDeal";
import { SigningMethod } from "models/offer/DealContact";
import { ServiceCategory } from "models/offer/ServiceLine";
import { LoadingStatus, RootState } from "state";
import { appendError, appendToastMessage } from "state/notifications";
import { SearchBy, searchForCustomer } from "state/offer/companyThunks";
import {
  fetchDeal,
  fetchLowestPossiblePrice,
  patchOffer,
  sendOfferForSigning,
  updateServicesAndPostDealData,
} from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import useValidateSmallRecurringCustomersServices from "hooks/createNew/offer/use-validate-small-recurring-customers-services";
import { useRoutingForOffer } from "views/createNew/offer/components/wizardSection/useRoutingForOffer";
import ContractTexts from "views/createNew/offer/wizard/Summary/components/ContractTexts";
import FinalPrice from "views/createNew/offer/wizard/Summary/components/FinalPrice/FinalPrice";
import ServiceInfoTable from "views/createNew/offer/wizard/Summary/components/ServiceInfoTable";
import InvalidSigningMethodModal from "views/createNew/offer/wizard/Summary/components/modals/InvalidSigningMethodModal";
import MissingProjectManagerModal from "views/createNew/offer/wizard/Summary/components/modals/MissingProjectManagerModal";
import MissingSmallBusinessYear from "views/createNew/offer/wizard/Summary/components/modals/MissingSmallBusinessYear";
import UnapprovedServicesModal from "views/createNew/offer/wizard/Summary/components/modals/UnapprovedServicesModal";
import { WizardSection } from "views/createNew/offer/components/wizardSection";
import { OfferRouteHelper } from "views/createNew/offer/routes/offerRoutes";
import { LoadingStatusEnum } from "constants/enums/LoadingStatus.enum";
import { numberAsSwedishCurrency } from "libs/number-format";

import "./summary.scss";
import { UserRoles } from "models/user";

type FinalPriceSectionProps = {
  title: string;
  loadingStatus: LoadingStatus;
  shouldOpen: boolean;
  lowestPossiblePrice?: number;
};

function FinalPriceSection({
  title,
  shouldOpen,
  lowestPossiblePrice,
}: FinalPriceSectionProps) {
  const { translate } = useTranslation();

  const subtitles = [
    translate("PRICE"),
    translate("SUGGESTED_PRICE"),
    translate("DISCOUNT"),
    translate("FINAL_PRICE"),
  ];

  const showLowestPossiblePrice =
    !!lowestPossiblePrice && lowestPossiblePrice > 0;

  return (
    <SolidAccordion
      title={title}
      subtitles={subtitles}
      limitSubtitles={subtitles.length}
      shouldOpen={shouldOpen}
    >
      <>
        {showLowestPossiblePrice && (
          <div className="d-flex align-items-center mb-md ">
            <Info20Regular className="color-blue mr-sm" />
            <span className="text-gray">
              {translate("SUMMARY_PAGE.LOWEST_POSSIBLE_PRICE", [
                numberAsSwedishCurrency(lowestPossiblePrice),
              ])}
            </span>
          </div>
        )}
        <FinalPrice />
      </>
    </SolidAccordion>
  );
}

type ContractTextsSectionProps = {
  title: string;
  contractTexts: Appendix[];
};

function ContractTextsSection({
  title,
  contractTexts,
}: ContractTextsSectionProps) {
  const subtitles = contractTexts.map(
    (contractText) => contractText.appendix_title
  );
  return (
    <SolidAccordion
      title={title}
      subtitles={subtitles}
      limitSubtitles={subtitles.length}
    >
      <ContractTexts contractTexts={contractTexts} />
    </SolidAccordion>
  );
}

function Summary() {
  const dispatch: AppDispatch = useDispatch();
  const { translate } = useTranslation();
  const { offerId, orgId } = useParams<{ orgId: string; offerId: string }>();
  const navigate = useNavigate();
  const {
    GetCategoriesWithMissingPM,
    GetCategoriesWithMissingYear,
    IsSignerValid,
    GetSigner,
    GetLowestPossiblePrice,
    GetIsFinalPriceLowerThanLowestPossible,
    GetIsPriceAutoAdjusted,
    GetIsPriceAcceptable,
    isSmallRecurring,
    GetAllCategoriesFlattened,
    GetComparedFinalPriceToProposedPrice,
    GetIsPriceAdjustmentAndDiscountEqualToMinAdjustment,
    MinPriceAdjustmentIsDefined,
  } = useServiceMatrix();

  const { validateServices } = useValidateSmallRecurringCustomersServices();

  const {
    currentOffer: {
      data: currentOffer,
      status: loadingStatus,
      lowestPossiblePriceNewCustomers,
      isPriceCalculated,
    },
  } = useSelector((state: RootState) => state.offers);

  const { currentUser: user } = useSelector((state: RootState) => state.users);

  const [renderExternalContent, setRenderExternalContent] =
    useState<ReactNode>("");

  const [showPriceAdjustmentModal, setShowPriceAdjustmentModal] =
    useState(false);

  // This checkbox is added as per https://lrfkonsult.atlassian.net/browse/POG-1006
  // as a temporary replacement for the Approval function, and is only on FE
  // To be removed when https://lrfkonsult.atlassian.net/browse/POG-837 is implemented
  const [isApproved, setIsApproved] = useState(false);

  const [isPriceValid, setIsPriceValid] = useState(true);

  const priceAdjustmentAccordionRef = useRef<HTMLDivElement>(null);

  const lowestPrice = GetLowestPossiblePrice();

  const [showModal, setShowModal] = useState<{
    header: ReactNode;
    content: ReactNode;
    footer?: ReactNode;
    warning?: boolean;
  }>({ header: "", content: "" });

  const closeModal = () => {
    setShowModal({ header: "", content: "" });
  };

  const location = useLocation();
  const routing = useRoutingForOffer(location.pathname, currentOffer);

  const showPriceAdjustmentWarningModal = () => {
    if (
      !isSmallRecurring() ||
      !isLoading() ||
      !isPriceCalculated ||
      loadingStatus === LoadingStatusEnum.FAILED
    ) {
      setShowPriceAdjustmentModal(false);
      return;
    }

    setIsApproved(false);
    const categories = GetAllCategoriesFlattened(currentOffer);
    const services = categories.flatMap((category) => category.services);

    const servicesValidity = validateServices(services);

    const isValid = () => {
      return servicesValidity.every(
        (sv) => sv.isValueValid && sv.isFrequencyValid
      );
    };

    // Display the price warning dialog if:
    // 1. The price is not acceptable, and
    // 2. The price adjustment and the discount are defined, but
    //    They are not equal to the min_price_adjustment, and
    // 3. min_price_adjustment is defined
    const shouldShowPriceAdjustmentModal = () => {
      return (
        !GetIsPriceAcceptable() &&
        !GetIsPriceAdjustmentAndDiscountEqualToMinAdjustment() &&
        MinPriceAdjustmentIsDefined()
      );
    };

    const isYearEndMonthValid = () => {
      return categories.every((category) => !!category.year_end_month);
    };

    if ((!isValid() || !isYearEndMonthValid()) && routing.previous?.path) {
      navigate(routing.previous?.path);
    }

    if (currentOffer?.new_customer) {
      setShowPriceAdjustmentModal(GetIsFinalPriceLowerThanLowestPossible());
    } else {
      setShowPriceAdjustmentModal(shouldShowPriceAdjustmentModal());
    }
    setIsPriceValid(!GetIsFinalPriceLowerThanLowestPossible());
  };

  useEffect(() => {
    showPriceAdjustmentWarningModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffer, loadingStatus]);

  const handleCloseAdjustPriceModal = () => {
    setShowPriceAdjustmentModal(false);

    if (priceAdjustmentAccordionRef.current) {
      priceAdjustmentAccordionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    if (orgId && offerId && !currentOffer?.id) {
      dispatch(
        searchForCustomer({
          searchData: {
            customerId: orgId,
            searchByParam: SearchBy.OrgNumber,
          },
          checkCustomerBlocked: false,
        })
      );
      dispatch(fetchDeal({ dealId: offerId, orgId }));
    }
  }, [orgId, offerId, dispatch, currentOffer?.id]);

  useEffect(() => {
    if (
      currentOffer?.customer?.legal_form &&
      currentOffer?.id &&
      currentOffer.new_customer &&
      !lowestPossiblePriceNewCustomers &&
      isSmallRecurring()
    ) {
      dispatch(
        fetchLowestPossiblePrice({
          isNewCustomer: currentOffer.new_customer,
          companyType: currentOffer.customer.legal_form,
        })
      );
    }
  }, [
    currentOffer,
    dispatch,
    lowestPossiblePriceNewCustomers,
    isSmallRecurring,
  ]);

  const serviceLines = (currentOffer?.service_areas ?? []).flatMap(
    (sa) => sa.service_lines
  );

  const allServiceCategories = serviceLines
    .flatMap((sl) => sl.service_groups)
    .flatMap((sg) => sg.service_categories);

  if (!allServiceCategories) {
    throw new Error("No service categories found");
  }

  const copyToClipBoard = async () => {
    if (!currentOffer?.customer?.org_number || !currentOffer?.id) {
      dispatch(
        appendError("OFFER_LINK_COPY_FAILED", {
          message: "Offer id or customer number missing",
        } as Error)
      );
      return;
    }

    const link = `${
      window.location.origin +
      OfferRouteHelper.getExistingSummary(
        currentOffer.customer.org_number,
        currentOffer.id
      )
    }`;
    try {
      await navigator.clipboard.writeText(link);
    } catch (err) {
      if (!copy(link)) {
        dispatch(appendError("OFFER_LINK_COPY_FAILED", err as Error));
      }
    } finally {
      dispatch(appendToastMessage("OFFER_LINK_COPIED", "success"));
    }
  };

  function renderUnapprovedModal(onApprove: () => void) {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      footer: (
        <ModalFooter
          onSave={() => {
            onApprove();
            closeModal();
          }}
          onCancel={closeModal}
          labelSubmit="YES"
          labelCancel="NO"
        />
      ),
      content: (
        <div className="d-flex flex-column justify-content-center">
          <div className="p-xs d-flex ">
            {translate("UNAPPROVE_MODAL_MESSAGE")}
          </div>
        </div>
      ),

      warning: true,
    });
  }

  const toggleApproveServiceCategory = (serviceCategory: ServiceCategory) => {
    if (!currentOffer?.service_areas) {
      return;
    }

    const updatedAreas = ServiceCategory.updateServiceCategoryInServiceArea(
      currentOffer.service_areas,
      { ...serviceCategory, approved_by_email: user.email }
    );

    if (serviceCategory.approved_by_email) {
      renderUnapprovedModal(() => {
        dispatch(
          updateServicesAndPostDealData({
            service_areas: updatedAreas,
          })
        );
      });
      return;
    }

    dispatch(
      updateServicesAndPostDealData({
        service_areas: updatedAreas,
      })
    );
  };

  const showFailedSendForSigningModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="WARNING" />,
      content: (
        <div className="d-flex px-lg pb-xs text-color-red align-items-center">
          <ErrorCircle20Regular className="mr-sm" />
          <span className="p-xxs ">{translate("FAILS_TO_SEND_OFFER")}</span>
        </div>
      ),
      warning: true,
    });
  };

  const handleSendDigitalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      const response = await dispatch(
        sendOfferForSigning({ dealId: currentOffer.id })
      );

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getCompletion());
      }

      if (response.meta.requestStatus === "rejected") {
        showFailedSendForSigningModal();
      }
    }
  };

  const showSendDigitalDealConfirmationModal = () => {
    setShowModal({
      header: <ModalHeader headerTitleText="CONFIRMATION_MODAL_TITLE" />,
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_SEND_OFFER"
          onSave={handleSendDigitalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs pb-lg">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const handleSendPhysicalDeal = async () => {
    if (currentOffer?.id) {
      closeModal();

      if (currentOffer && currentOffer.state === "contract") {
        navigate(OfferRouteHelper.getManualSignKyc());
        return;
      }

      const response = await dispatch(patchOffer({ state: "contract" }));

      if (response.meta.requestStatus === "fulfilled") {
        navigate(OfferRouteHelper.getManualSignKyc());
      }
    }
  };

  const showSendPhysicalDealConfirmationModal = () => {
    setShowModal({
      header: (
        <ModalHeader headerTitleText="CONFIRMATION_PHYSICAL_MODAL_TITLE" />
      ),
      footer: (
        <ModalFooter
          labelCancel="NO_CANCEL"
          onCancel={closeModal}
          labelSubmit="YES_PROCEED"
          onSave={handleSendPhysicalDeal}
        />
      ),
      content: (
        <div className="d-flex flex-column p-xxs">
          <div className="d-flex flex-column p-xxs">
            <div className="fw-bold d-flex justify-content-center">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_1")}
            </div>
            <div className="d-flex justify-content-center mt-md">
              {translate("CONFIRMATION_PHYSICAL_MODAL_TEXT_2")}
            </div>
          </div>
        </div>
      ),
    });
  };

  const sendDealForSigning = async () => {
    const isDealMissingContactData = !(currentOffer?.contacts || []).length;

    if (!IsSignerValid() || isDealMissingContactData) {
      setRenderExternalContent(
        <InvalidSigningMethodModal
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const signerContact = GetSigner();
    let isSigningMethodPhysical = false;
    if (
      signerContact?.is_signer &&
      signerContact.signing_method === SigningMethod.PHYSICAL
    ) {
      // If we have selected a physical signing method we send the deal for signing in the next step
      isSigningMethodPhysical = true;
    }

    // check if all selected services are approved by manager
    if (!currentOffer?.id) {
      setRenderExternalContent(
        <UnapprovedServicesModal
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if there is a service category that is missing project manager
    const servicesMissingProjectManager = GetCategoriesWithMissingPM();
    if (servicesMissingProjectManager.length) {
      setRenderExternalContent(
        <MissingProjectManagerModal
          missingPMforServices={servicesMissingProjectManager.map(
            (s) => s.name
          )}
          onCancel={() => setRenderExternalContent("")}
        />
      );
      return false;
    }

    const servicesMissingYear = GetCategoriesWithMissingYear();
    if (servicesMissingYear.length) {
      setRenderExternalContent(
        <MissingSmallBusinessYear
          onCancel={() => setRenderExternalContent("")}
        />
      );

      return false;
    }

    // check if the signing method has valid data for the signing method
    if (!isSigningMethodPhysical) {
      const contactHasInvalidEmail =
        isPlaceholderEmail(signerContact?.contact.email ?? "") ||
        signerContact?.contact.email === "";

      if (contactHasInvalidEmail) {
        setRenderExternalContent(
          <InvalidSigningMethodModal
            onCancel={() => setRenderExternalContent("")}
          />
        );
        return false;
      }
    }

    if (isSigningMethodPhysical) {
      showSendPhysicalDealConfirmationModal();
    } else {
      showSendDigitalDealConfirmationModal();
    }

    return false;
  };

  const isLoading = () => currentOffer?.id;

  const getPriceAdjustmentModalContent = () => {
    if (currentOffer?.new_customer) {
      return (
        <div>
          <p className="fw-600 fpx-18">
            {translate("ADJUST_PRICE_MODAL.PRICE_CANNOT_BE_LOWER")}
            <span className="text-color-yellow-dark fpx-24">
              {` ${numberAsSwedishCurrency(lowestPrice ?? 0)}`}
            </span>
          </p>
          <p className="fpx-16">
            {translate("ADJUST_PRICE_MODAL.PLEASE_ADJUST_PRICE")}
          </p>
        </div>
      );
    }

    return (
      <>
        {GetIsPriceAutoAdjusted() &&
          GetComparedFinalPriceToProposedPrice(
            FinalPriceComparisonType.EQUAL
          ) && (
            <div>
              <p className="fw-600 fpx-18">
                {translate("ADJUST_PRICE_MODAL.PRICE_CANNOT_BE_LOWER")}
                <span className="text-color-yellow-dark fpx-24">
                  {` ${numberAsSwedishCurrency(lowestPrice ?? 0)}`}
                </span>
              </p>
              <p className="fpx-16">
                {translate("ADJUST_PRICE_MODAL.PRICE_IS_AUTO_ADJUSTED")}
              </p>
            </div>
          )}
        {GetComparedFinalPriceToProposedPrice(
          FinalPriceComparisonType.HIGHER
        ) && (
          <p className="fpx-16">
            {translate("ADJUST_PRICE_MODAL.PRICE_IS_HIGHER_THAN_PROPOSED")}
          </p>
        )}
        {GetIsFinalPriceLowerThanLowestPossible() && (
          <p className="fpx-16">
            {translate("SUMMARY_PAGE.LOWEST_POSSIBLE_PRICE", [
              numberAsSwedishCurrency(lowestPrice ?? 0),
            ])}
          </p>
        )}
      </>
    );
  };

  const headerText =
    currentOffer?.new_customer || GetIsPriceAutoAdjusted()
      ? "ADJUST_PRICE"
      : "WARNING";

  return (
    <WizardSection
      loadingStatus={isLoading() ? "idle" : "pending"}
      className="mr-0 ml-0"
      isBackHidden={!isDealEditable(currentOffer)}
      isNextHidden={
        (!isDealEditable(currentOffer) &&
          !canUploadPhysicalDeal(currentOffer)) ||
        (isSmallRecurring() && !isApproved)
      }
      nextButtonText={translate("COMPLETE")}
      onBeforeNext={async () => sendDealForSigning()}
      content={
        <div className="w-100 d-flex justify-content-end">
          <TooltipInfoIcon
            tooltipIcon={
              <Copy16Regular
                className="cursor-pointer text-color-blue"
                onClick={copyToClipBoard}
              />
            }
            tooltip={{ content: "COPY_LINK_TO_OFFER" }}
          />
        </div>
      }
      approvalCheckbox={
        isSmallRecurring()
          ? {
              isApproved,
              setIsApproved,
              isDisabled: !isPriceValid && !user.hasRole(UserRoles.USERS_PILOT),
            }
          : undefined
      }
    >
      {renderExternalContent && <>{renderExternalContent} </>}
      <Modal
        header={showModal.header}
        footer={showModal.footer}
        isOpen={showModal.content !== ""}
        onDismiss={() => closeModal()}
        isWarning={showModal.warning}
        size="medium"
      >
        {showModal.content}
      </Modal>
      <Row>
        <Col xs={12}>
          <ServiceInfoTable
            serviceLines={serviceLines}
            onToggleApproveServiceCategory={toggleApproveServiceCategory}
            isLoading={loadingStatus === "pending"}
          />
        </Col>

        <Col xs={12} ref={priceAdjustmentAccordionRef}>
          <FinalPriceSection
            title={translate("PRICE_ADJUSTMENT")}
            loadingStatus={loadingStatus}
            shouldOpen={GetIsPriceAutoAdjusted()}
            lowestPossiblePrice={lowestPrice}
          />
        </Col>
        <Col xs={12}>
          <ContractTextsSection
            contractTexts={currentOffer?.contract_texts ?? []}
            title={translate("OFFER_ASSIGNMENT_AGREEMENT")}
          />
        </Col>
      </Row>

      {currentOffer?.state === "offer" && loadingStatus === "pending" ? (
        <OverlaySpinner />
      ) : null}

      {showPriceAdjustmentModal && loadingStatus !== LoadingStatusEnum.PENDING && (
        <Modal
          header={<ModalHeader headerTitleText={headerText} />}
          footer={
            <ModalFooter
              hideSubmit
              labelSubmit=""
              onSave={() => {}}
              onCancel={handleCloseAdjustPriceModal}
              labelCancel="OK"
            />
          }
          isOpen={showPriceAdjustmentModal}
          onDismiss={handleCloseAdjustPriceModal}
        >
          {getPriceAdjustmentModalContent()}
        </Modal>
      )}
    </WizardSection>
  );
}

export default Summary;

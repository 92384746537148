import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Spinner,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
} from "@fluentui/react-components";

import { Button } from "components/button";
import { SpinnerSize } from "components/spinner";
import { useTranslation } from "hooks/use-translate";
import { dateLanguageFormatter } from "libs/date/date-format";
import { Activity } from "models/activities/activity";
import { Customer } from "models/customer";
import { CustomerDetailsParams, ServicesParams } from "routes";
import { RootState } from "state";
import { EditActivityStatus } from "state/activities/actions";
import Checkbox from "components/checkbox";
import { DatePicker } from "components/date/DatePicker";
import {
  ActivityGroup,
  OptionalActivitiesGroup,
} from "views/conveyorBelt/components/serviceActivities";
import { EditActivitiesGroup } from "./EditActivitiesGroup";

const UNNAMED_GROUP = "UNNAMED_GROUP";

type Props = {
  customer: Customer;
  showFrequency?: boolean;
  disabled: boolean;
  customerOptionalActivitiesLoading: boolean;
  customerOptionalActivities: Activity[];
  onSave: (
    toAdd: EditActivityStatus[],
    toRemove: EditActivityStatus[],
    isOnetime: boolean,
    toAddStartDate?: Date,
    toRemoveEndDate?: Date,
    serviceBoxId?: string
  ) => void;
  onCancel?: () => void;
  openActivityDetailsPanel: (activity: Activity) => void;
};

export function EditActivitiesTable({
  disabled,
  onSave,
  onCancel,
  customerOptionalActivities,
  customerOptionalActivitiesLoading,
  openActivityDetailsPanel,
  showFrequency = false,
}: Props) {
  const { translate, language } = useTranslation();
  const formatter = dateLanguageFormatter(language, "yyyy-MM-dd");
  const { customerId } = useParams<CustomerDetailsParams>();
  const { serviceId } = useParams<ServicesParams>();
  const customers = useSelector((state: RootState) => state.customers);
  const customer = customers.data.find(
    (c) => c.customer.customer_number === customerId
  );
  const { dataMap: definitionsMap } = useSelector(
    (state: RootState) => state.activities.definitions
  );

  // Use a list of activity IDs and statuses to keep track of what needs to be added / removed / unchanged
  const [editActivitiesStatuses, setEditActivitiesStatuses] = useState<
    EditActivityStatus[]
  >([]);
  const [selectedStartDate, setStartDate] = useState<Date | undefined>();
  const [selectedEndDate, setEndDate] = useState<Date | undefined>();
  const [isOnetime, setIsOnetime] = useState<boolean>(false);

  const { isLoading } = useSelector(
    (state: RootState) => state.activities.optionalActivities
  );

  // groups the activities by the definition.create_item_header property
  const activityGroupsMap = customerOptionalActivities.reduce(
    (sum, current) => {
      const definition = definitionsMap[current.activity_type];
      const groupName =
        current.getCreateItemHeader(language, definition) ||
        translate("UNNAMED_GROUP");

      if (!sum[groupName]) {
        sum[groupName] = {
          categoryName: groupName,
          activities: [],
        };
      }

      sum[groupName].activities.push(current);

      return sum;
    },
    {} as OptionalActivitiesGroup
  );

  useEffect(() => {
    // Map optional activities to a list of statuses
    setEditActivitiesStatuses(
      customerOptionalActivities.map((activity) => ({
        activity_type: activity.activity_type,
        selected: !activity.available_to_add,
      }))
    );
  }, [customerOptionalActivities]);

  const isSaveButtonEnabled = () => {
    const noActivitiesToChange =
      toAddActivities.length === 0 && toRemoveActivities.length === 0;
    const missingStartDate = toAddActivities.length > 0 && !selectedStartDate;
    const missingEndDate = toRemoveActivities.length > 0 && !selectedEndDate;

    return !(
      customerOptionalActivitiesLoading ||
      noActivitiesToChange ||
      missingStartDate ||
      missingEndDate ||
      isLoading
    );
  };

  const save = () => {
    if (!customer) {
      return;
    }

    if (selectedStartDate || selectedEndDate) {
      onSave(
        toAddActivities,
        toRemoveActivities,
        isOnetime,
        selectedStartDate,
        selectedEndDate,
        serviceId
      );
    }
  };

  // Find the difference between what activities are already added to the customer (from CB database) and what is currently selected in the UI
  const diffEditStatuses = () => {
    // Find the optionals that aren't already in the service activities and should be added
    const toAddActivities = editActivitiesStatuses.filter((a) => {
      const previouslyAdded = customerOptionalActivities.find(
        (co) => co.activity_type === a.activity_type
      );

      if (!previouslyAdded) {
        throw new Error("Could not map activity status");
      }

      return a.selected && previouslyAdded.available_to_add;
    });

    // Find the optionals that are in the service activities, but not in the selected optionals
    const toRemoveActivities = editActivitiesStatuses.filter((a) => {
      const previouslyAdded = customerOptionalActivities.find(
        (co) => co.activity_type === a.activity_type
      );

      if (!previouslyAdded) {
        throw new Error("Could not map activity status");
      }

      return !a.selected && !previouslyAdded.available_to_add;
    });

    return { toAddActivities, toRemoveActivities };
  };

  if (!isLoading && editActivitiesStatuses.length === 0) {
    return <p>Customer is missing any optional activities.</p>;
  }

  const { toAddActivities, toRemoveActivities } = diffEditStatuses();

  return (
    <div className={`edit-activities ${disabled && "disabled"} mt-2`}>
      {activityGroupsMap &&
        Object.keys(activityGroupsMap)
          .filter((key) => key !== UNNAMED_GROUP)
          .map((key) => {
            const group: ActivityGroup = activityGroupsMap[key];

            return (
              <EditActivitiesGroup
                key={group.categoryName}
                className="mb-sm"
                setEditActivitiesStatuses={setEditActivitiesStatuses}
                openActivityDetailsPanel={openActivityDetailsPanel}
                showFrequency={showFrequency}
                group={group}
                editActivitiesStatuses={editActivitiesStatuses}
              />
            );
          })}

      {customerOptionalActivities.length > 0 && (
        <MessageBar className="mt-4" intent="warning" layout="multiline">
          <MessageBarBody>
            <p
              className="m-0"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate(
                  "CHANGES_WILL_APPLY_TO_ALL_COMING_ACTIVITIES"
                ),
              }}
            />

            {toAddActivities.length > 0 && (
              <>
                <p className="m-0 py-sm">{translate("ADDING_ACTIVITIES")}:</p>
                <ul>
                  {toAddActivities.map((toAdd) => {
                    const activity = customerOptionalActivities.find(
                      (optional) =>
                        optional.activity_type === toAdd.activity_type
                    );

                    if (!activity) {
                      throw new Error("Could not map activity");
                    }

                    const definition = definitionsMap[activity.activity_type];
                    return (
                      <li key={activity.activity_type}>
                        {activity.getTitle(language, definition)} (
                        {translate(activity.getRecurrence(definition))})
                      </li>
                    );
                  })}
                </ul>
                <div className="d-flex mb-md align-items-center">
                  <span className="me-2">{translate("START_DATE")}</span>
                  <DatePicker
                    selectedDate={selectedStartDate ?? null}
                    onSelectDate={(date) => setStartDate(date ?? undefined)}
                    placeholder={translate("SET_START_DATE")}
                    dateFormatter={(date) => formatter(date, "dd-MM-yyyy")}
                    borderless
                  />
                  <div className="d-flex ml-sm align-items-center">
                    <span className="me-2">{translate("IS_ONETIME")}</span>
                    <Checkbox
                      checked={isOnetime}
                      onChange={() => setIsOnetime(!isOnetime)}
                    />
                  </div>
                </div>
              </>
            )}

            {toRemoveActivities.length > 0 && (
              <>
                <p className="m-0 py-sm">{translate("REMOVING_ACTIVITIES")}:</p>
                <ul>
                  {toRemoveActivities.map((toRemove) => {
                    const activity = customerOptionalActivities.find(
                      (optional) =>
                        optional.activity_type === toRemove.activity_type
                    );

                    if (!activity) {
                      throw new Error("Could not map activity");
                    }

                    const definition = definitionsMap[activity.activity_type];
                    return (
                      <li key={activity.activity_type}>
                        {activity.getTitle(language, definition)} (
                        {translate(activity.getRecurrence(definition))})
                      </li>
                    );
                  })}
                </ul>
                <div className="d-flex mb-md align-items-center">
                  <span className="me-2">{translate("END_DATE")}</span>
                  <DatePicker
                    selectedDate={selectedEndDate}
                    onSelectDate={(date) => setEndDate(date ?? undefined)}
                    dateFormatter={(date) => formatter(date, "dd-MM-yyyy")}
                  />
                </div>
              </>
            )}
            {isLoading && (
              <Spinner
                size={SpinnerSize.ExtraSmall}
                className="my-2 justify-content-start"
                label="Saving changes..."
              />
            )}
          </MessageBarBody>
          <MessageBarActions className="justify-content-start pl-lg ml-xs">
            <Button
              className="me-2"
              disabled={!isSaveButtonEnabled()}
              onClick={save}
            >
              {translate("CONFIRM")}
            </Button>
            {onCancel && (
              <Button variant="outline" onClick={onCancel}>
                {translate("CANCEL")}
              </Button>
            )}
          </MessageBarActions>
        </MessageBar>
      )}

      {!customerOptionalActivitiesLoading &&
        customerOptionalActivities.length === 0 && (
          <MessageBar intent="warning" layout="multiline">
            <p className="m-0">
              {translate("NO_AVAILABLE_OPTIONAL_ACTIVITIES")}
            </p>
            <MessageBarActions className="justify-content-start pl-lg ml-xs">
              {onCancel && (
                <Button onClick={onCancel}>{translate("OK")}</Button>
              )}
            </MessageBarActions>
          </MessageBar>
        )}
    </div>
  );
}

import { ReactNode } from "react";
import { Col, Row } from "react-bootstrap";

import {
  Content,
  ContentBody,
  ContentFooter,
} from "components/content/Content";
import { Breadcrumb, ContentHeader } from "components/content/ContentHeader";
import Checkbox from "components/checkbox";
import { useTranslation } from "hooks";
import Tooltip from "components/tooltip/Tooltip";
import { SectionProps } from "components/section/Section";
import { LoadingStatus } from "state";
import WizardBackButton from "./WizardBackButton";
import WizardForwardButton from "./WizardForwardButton";
import { useGetBreadcrumbs } from "./useGetBreadcrumbs";

type WizardSectionProps = {
  serviceName?: string;
  headerShrink?: boolean;
  isNextHidden?: boolean;
  isBackHidden?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onBeforeNext?: () => Promise<boolean>;
  onBeforePrevious?: () => void;
  predefinedNextRoute?: string;
  hideNavigation?: boolean;
  titleBreadCrumb?: string;
  numberBreadCrumb?: number;
  loadingStatus?: LoadingStatus;
  content?: ReactNode;
  backButtonText?: string;
  nextButtonText?: string;
  subtitles?: string[];
  isRoutingButton?: boolean;
  customBackButtonRoute?: string;
  hasDivider?: boolean;
  approvalCheckbox?: {
    isApproved: boolean;
    setIsApproved: (isApproved: boolean) => void;
    isDisabled: boolean;
  };
} & SectionProps;

export function WizardSection({
  serviceName = "",
  onNext = () => true,
  onPrevious,
  isNextHidden,
  headerShrink = false,
  isBackHidden,
  onBeforeNext,
  onBeforePrevious,
  children,
  predefinedNextRoute,
  hideNavigation = false,
  titleBreadCrumb,
  numberBreadCrumb,
  loadingStatus,
  content,
  backButtonText,
  nextButtonText,
  subtitles,
  isRoutingButton = true,
  customBackButtonRoute,
  hasDivider = false,
  approvalCheckbox,
}: WizardSectionProps) {
  const { getBreadcrumbs } = useGetBreadcrumbs();
  const { translate } = useTranslation();

  const renderNavigation = () => {
    if (hideNavigation) {
      return null;
    }

    return (
      <Row
        className={`stepper-buttons ${
          isBackHidden ? "justify-content-end" : "justify-content-between"
        }`}
      >
        {!isBackHidden && (
          <Col md="auto">
            <WizardBackButton
              customBackButtonRoute={customBackButtonRoute}
              backButtonText={backButtonText}
              loadingStatus={loadingStatus}
              onBeforePrevious={onBeforePrevious}
              onPrevious={onPrevious}
            />
          </Col>
        )}
        {/* This checkbox is added as per https://lrfkonsult.atlassian.net/browse/POG-1006
        as a temporary replacement for the Approval function, and is only on FE
        To be removed when https://lrfkonsult.atlassian.net/browse/POG-837 is implemented */}
        {approvalCheckbox && (
          <Col className="d-flex align-items-center justify-content-end">
            <Tooltip content="SUMMARY_PAGE.APPROVAL_CHECKBOX.TOOLTIP">
              <Checkbox
                label={translate(
                  "SUMMARY_PAGE.APPROVAL_CHECKBOX.APPROVED_BY_COMPETENT"
                )}
                labelPosition="before"
                checked={approvalCheckbox.isApproved}
                disabled={approvalCheckbox.isDisabled}
                onChange={(_, { checked }) => {
                  if (checked !== "mixed") {
                    approvalCheckbox.setIsApproved(checked);
                  }
                }}
              />
            </Tooltip>
          </Col>
        )}
        <Col md="auto">
          <WizardForwardButton
            isRoutingButton={isRoutingButton}
            predefinedNextRoute={predefinedNextRoute}
            nextButtonText={nextButtonText}
            loadingStatus={loadingStatus}
            onNext={onNext}
            onBeforeNext={onBeforeNext}
            isDisabled={isNextHidden}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Content isLoading={loadingStatus === "pending"}>
      <ContentHeader
        breadcrumbs={
          getBreadcrumbs(
            serviceName,
            false,
            numberBreadCrumb,
            titleBreadCrumb
          ) as Breadcrumb[]
        }
        subtitles={subtitles}
        loadingStatus={loadingStatus}
        hasDivider={hasDivider}
        content={content}
        headerShrink={headerShrink}
      />

      <ContentBody>{children}</ContentBody>

      <ContentFooter>{renderNavigation()}</ContentFooter>
    </Content>
  );
}

import AppConfig from "app-config";
import { getAuthorizationHeader } from "./libs/auth-header";

export class IntranetAPI {
  static async getIntranetPosts(token: string) {
    const response = await fetch(`${AppConfig.API_URL}/intranet/posts`, {
      headers: {
        ...getAuthorizationHeader(token),
      },
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.json();
  }
}

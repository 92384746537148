import { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Picker } from "components/picker";
import { useTranslation } from "hooks/use-translate";
import { PickerOption, SelectedPickerOption } from "models/offer/Picker";
import { RootState } from "state";
import {
  addSelectedServiceOption,
  removeSelectedServiceOption,
  resetSelectedServiceOptions,
  resetSelectedServiceType,
  setSelectedServiceType,
} from "state/offer/offersSlice";
import { resetOffer } from "state/offer/offersThunks";
import { AppDispatch } from "state/use-app-redux";
import { AppRouteHelper } from "routes";
import { WizardSection } from "../../components/wizardSection";
import { availableServiceTypes } from "../configuration/service-type-config";
import { getNextButtonText, OfferRouteHelper } from "../../routes/offerRoutes";
import { ServicesContext } from "../Services/ServicesContextProvider";

export default function ServiceType() {
  const { translate, ts } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const selectedServiceType = useSelector(
    (state: RootState) => state.offers.selectedServiceType
  );

  const selectedOptions = useSelector(
    (state: RootState) => state.offers.selectedServiceOptions
  );
  const { resetServicesContext } = useContext(ServicesContext);

  useEffect(() => {
    dispatch(resetOffer());
    dispatch(resetSelectedServiceType());
    dispatch(resetSelectedServiceOptions());
    resetServicesContext();
  }, [dispatch, resetServicesContext]);

  const isOptionSelected = (option: PickerOption) => {
    return !!selectedOptions?.find(
      (so: SelectedPickerOption) => so.name === option.name
    );
  };

  const toggleOption = (option: PickerOption, groupIndex: number) => {
    if (isOptionSelected(option)) {
      dispatch(removeSelectedServiceOption({ ...option, groupId: groupIndex }));
    } else {
      dispatch(addSelectedServiceOption({ ...option, groupId: groupIndex }));
    }
  };

  /**
   * The next route depends on the selected options
   */
  const getPredefinedNextRoute = () => {
    if (
      selectedOptions.length > 0 &&
      (selectedServiceType === availableServiceTypes[0].name ||
        selectedServiceType === availableServiceTypes[1].name)
    ) {
      return OfferRouteHelper.getOffersRedirect();
    }

    return undefined;
  };

  const getServiceTypeDescription = (serviceTypeName: string) => {
    return selectedServiceType === serviceTypeName
      ? ts(`${serviceTypeName}_SELECTED_DESCRIPTION`)
      : ts(`${serviceTypeName}_DESCRIPTION`);
  };

  return (
    <WizardSection
      isNextHidden={
        !selectedOptions.length &&
        selectedServiceType !== availableServiceTypes[0].name
      }
      predefinedNextRoute={getPredefinedNextRoute()}
      nextButtonText={ts(
        getNextButtonText(
          location.pathname,
          undefined,
          selectedServiceType,
          selectedOptions
        )
      )}
      subtitles={[translate("SERVICE_TYPE_SELECTION_SUBTITLE")]}
      customBackButtonRoute={AppRouteHelper.getCreateNew()}
      isBackHidden={false}
    >
      <Row className="minHeight-100">
        <Col xl={3} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
        <Col xl={6} lg={8} className="m-auto">
          <div className="d-flex flex-wrap justify-content-center fg-4">
            {availableServiceTypes.map((serviceType) => (
              <Picker
                key={serviceType.name}
                name={ts(serviceType.name)}
                description={getServiceTypeDescription(serviceType.name)}
                useHTMLDescription
                excludingGroups
                checked={selectedServiceType === serviceType.name}
                className="mx-sm"
                onToggle={() => {
                  dispatch(resetSelectedServiceOptions());
                  if (selectedServiceType === serviceType.name) {
                    dispatch(resetSelectedServiceType());
                  } else {
                    dispatch(setSelectedServiceType(serviceType.name));
                  }
                }}
                toggles={serviceType.groups
                  .map((group, index) => {
                    return group.options.map((option) => {
                      return {
                        value: option.name,
                        label: option.name,
                        group: index,
                        checked: isOptionSelected(option),
                        onToggle: () => {
                          toggleOption(option, index);
                        },
                        disabled:
                          selectedOptions.length > 0 &&
                          !isOptionSelected(option),
                      };
                    });
                  })
                  .flat(1)}
              />
            ))}
          </div>
        </Col>
        <Col xl={3} lg={2} className="pt-lg m-auto">
          <div className="horizontal-divider" />
        </Col>
      </Row>
    </WizardSection>
  );
}

import { ReactNode } from "react";
import {
  Field,
  makeStyles,
  mergeClasses,
  SearchBox,
} from "@fluentui/react-components";
import type { SearchBoxProps } from "@fluentui/react-components";

import ConditionalWrapper from "components/conditionalWrapper";

type SearchProps = {
  label?: string;
  errorMessage?: ReactNode;
  fullWidth?: boolean;
  hintMessage?: ReactNode;
  onSearch?: (value: string) => void;
} & SearchBoxProps;

const useStyles = makeStyles({
  searchBox: {
    maxWidth: "100%",
  },
});

export default function SearchInput({
  label,
  errorMessage,
  fullWidth,
  hintMessage,
  onSearch,
  ...rest
}: SearchProps) {
  const styles = useStyles();

  return (
    <ConditionalWrapper
      condition={!!label || !!fullWidth}
      wrapper={<Field label={label} />}
    >
      <SearchBox
        {...rest}
        className={mergeClasses(styles.searchBox, rest.className ?? "")}
      />
      {hintMessage && hintMessage}
      {errorMessage && errorMessage}
    </ConditionalWrapper>
  );
}

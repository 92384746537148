import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

import { ServiceGroup } from "models/offer/ServiceLine";
import { TaxObjectService } from "models/offer/TaxObjectService";

export type TaxObjects = {
  incomeTax?: TaxObjectService[];
  smallBusiness?: TaxObjectService[];
  smallBusinessEF?: TaxObjectService[];
  smallRecurringMonthly?: TaxObjectService[];
  smallRecurringMonthlyEF?: TaxObjectService[];
  smallRecurringQuarterly?: TaxObjectService[];
  smallRecurringQuarterlyEF?: TaxObjectService[];
};

interface ServicesContextProps {
  serviceGroups: ServiceGroup[];
  taxObjects: TaxObjects;
  updateServiceGroups: (serviceGroup: ServiceGroup) => void;
  updateTaxObjects: (taxObjects: TaxObjects) => void;
  resetServicesContext: () => void;
}

export const ServicesContext = createContext<ServicesContextProps>({
  serviceGroups: [],
  taxObjects: {},
  updateServiceGroups: () => {},
  updateTaxObjects: () => {},
  resetServicesContext: () => {},
});

interface IServicesProvider {
  children: ReactNode;
}

export function ServicesProvider({ children }: IServicesProvider) {
  const [serviceGroups, setServiceGroups] = useState<ServiceGroup[]>([]);
  const [taxObjects, setTaxObjects] = useState<TaxObjects>({});

  const resetServicesContext = useCallback(() => {
    setTaxObjects({});
    setServiceGroups([]);
  }, []);

  const updateTaxObjects = useCallback((taxObject: TaxObjects) => {
    setTaxObjects((prev) => ({
      ...prev,
      ...taxObject,
    }));
  }, []);

  const updateServiceGroups = useCallback(
    (serviceGroup: ServiceGroup) => {
      const updatedServiceGroups = [...serviceGroups];
      const existingServiceGroupIndex = updatedServiceGroups.findIndex(
        (group) => group.name === serviceGroup.name
      );

      if (existingServiceGroupIndex === -1) {
        setServiceGroups([...serviceGroups, serviceGroup]);
      } else {
        updatedServiceGroups[existingServiceGroupIndex] = serviceGroup;
        setServiceGroups(updatedServiceGroups);
      }
    },
    [serviceGroups]
  );

  const initContextState = useMemo<ServicesContextProps>(
    () => ({
      serviceGroups,
      taxObjects,
      updateServiceGroups,
      updateTaxObjects,
      resetServicesContext,
    }),
    [
      serviceGroups,
      taxObjects,
      updateServiceGroups,
      updateTaxObjects,
      resetServicesContext,
    ]
  );

  return (
    <ServicesContext.Provider value={initContextState}>
      {children}
    </ServicesContext.Provider>
  );
}
